.chatzy-container {
  height: 100vh;
  display: flex;
  flex-direction: row;

  .main-nav {
    min-width: $main-nav-width;
    width: $main-nav-width;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid $border-color;
    padding: $sidebar-spacing 0;
  }

  .chatzy-left-sidebar {
    min-width: $left-sidebar-width;
    width: $left-sidebar-width;
    height: 100vh;
    max-height: 100vh;

    .template-title {
      padding-bottom: 20px;
      border-bottom: 1px solid rgba($muted-font, 0.1);
    }

    .contact-list-tab {
      .chat-main {
        li {
          border-bottom: 1px solid rgba($muted-font, 0.1);

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }

  .chatzy-main {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    z-index: 1;
    transition: all 0.7s;
    background-color: $white;
  }
}

.dropdown {
  .select {
    span {
      color: $primary-color;
    }
  }

  .dropdown-menu {
    .dropdown-divider {
      border-bottom: 1px solid $border-color2;
      background-color: $white;
    }
  }
}

.todo-main-modal {
  .todo-task {
    h5 {
      margin-bottom: 10px;
      color: $dark-color;
      font-weight: 600;
    }

    .todo-main-content {
      .drop-picker {
        display: flex;
        align-items: center;
        margin: 20px 0;
      }

      input {
        border: none;
        width: 50%;
        background-color: rgba($gray, 0.1);
        cursor: pointer;

        &::placeholder {
          color: $primary-color;
        }

        &:focus {
          outline: none;
        }
      }

      .form-group {
        background-color: rgba($gray, 0.1);
        display: flex;
        align-items: center;
        padding-left: 15px;

        input {
          width: 18px;

          +input {
            background-color: transparent;
            margin-left: 15px;
            padding-left: 10px;
          }
        }
      }
    }
  }
}

.create-todo-main-modal {
  .todo-task {
    h5 {
      margin-bottom: 10px;
      color: $dark-color;
      font-weight: 600;
    }

    .todo-main-content {
      .drop-picker {
        display: flex;
        align-items: center;
        margin: 20px;
        margin-left: 45px;
      }

      input {
        border: none;
        cursor: pointer;

        &::placeholder {
          color: $primary-color;
        }

        &:focus {
          outline: none;
        }
      }

      .form-group {
        display: flex;
        align-items: center;

        input {
          width: 18px;

          +input {
            margin-left: 15px;
            padding-left: 10px;
          }
        }
      }
    }
  }
}

.d-flex {
  display: flex;
  align-items: flex-start;

  .flex-grow-1 {
    flex: 1;
  }
}