@media (max-width: 1367px){
  // header //
  .header-inner {
    padding-bottom: 30px;
  }
}

@media (max-width:1199px){
  //counter css //
  .counter-box-second {
    .counter-box  {
      .counter-icon {
        font-size: 30px;
      }
      h6 {
        font-size: 22px;
      }
      span {
        font-size: 13px;
      }
    }
  }
}

@media (max-width: 991px ){
  .error-images {
    display: none;
   }

  .team .owl-nav  {
    top: -60px;
    button.owl-prev ,button.owl-next{
      height: 30px;
      width: 30px;
      &:before {
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 767px){
  // error section //
  .error-main  {
    .error-contain {
      .error-btn {
        padding: 15px 25px;
      }
    }
  }
}

@media (max-width: 575px){
  // header css //
  .header-inner {
    padding-bottom: 20px;
  }

  // error section //
  .error-main  {
    .error-contain  {
      .error-btn {
        padding: 12px 18px;
      }
    }
  }
}