@import "../public/assets/scss/app.scss";


.dropdown {
  transition: all 0.5s ease;
  color: #b2b6be;

  &.currency {
    padding: 2px;
    border-radius: 4px;
    background-color: transparent;
    width: 150%;

    &:focus {
      outline: none;
    }
  }

  .select {
    cursor: pointer;

    span {
      font-size: 15px;
      font-weight: 500;
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }

  .dropdown-menu {
    padding: 5px 0;
    min-width: 14rem;
    border-radius: 0;

    li {
      padding: 6px 15px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      display: block;
      color: #333333;
    }

    .dropdown-divider {
      height: 40px;
      display: flex;
      padding: 10px;
      margin: unset;

      h5 {
        margin-left: 10px;
      }
    }
  }
}

.dropdown-menu {
  padding: 15px 20px;
  border-radius: 15px;
  border: 1px solid #e5f0fa;
  min-width: 238px;

  .nav-link {
    line-height: 1;

    a,
    span {
      font-size: 14px;
    }
  }
}

.sidebar-active {
  .chatzy-container {
    .main-nav {
      &~.chatzy-main {
        .messages {
          .contact-details {
            width: 56vw;
          }
        }
      }
    }
  }
}
