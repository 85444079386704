.dashboard {
  background-color: rgba($primary-color, 0.1);

  .your-chats {
    .unread {
      .card {
        box-shadow: 0 0 20px 20px rgba($primary-color, 0.1);
      }
    }
  }
}