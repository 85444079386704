//  Template pannel start  //
@keyframes infinite-spinning {
  0% {
    transform: rotate(0deg) scale(1);
  }

  100% {
    transform: rotate(360deg) scale(1.2);
  }
}

.template-pannel-main {
  background-color: $white;
  position: fixed;
  top: 30%;
  right: 0;
  z-index: 9;
  padding: 0 5px;
  box-shadow: 0 0 7px 1px rgba(158, 158, 158, 0.2);

  ul {
    li {
      display: flex;
      padding: 10px 12px;
      border-bottom: 1px solid #dadada;

      span {
        padding: 0;
        text-transform: uppercase;
        color: #949494;
        font-weight: 700;
      }

      a {

        padding: 0;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.color-picker {
  .title {
    display: inline-block;
    position: absolute;
    left: -68px;
    width: 68px;
    box-shadow: -3px 0 7px 1px rgba(158, 158, 158, 0.2);
    background-color: $white;
    padding: 10px 14px;
    border-radius: 5px;
  }

  .colors {
    li {
      margin: 3px;
      display: inline-block;
      position: relative;
      border-radius: 5px;

      &.active {
        &:before {
          content: "\e6e0";
          position: absolute;
          font-family: themify;
          color: $white;
          font-size: 19px;
          top: 10px;
          left: 15px;
          transform: rotate(-90deg);
        }
      }
    }
  }
}

// setting sidebar //
.setting-sidebar {
  background-color: $white;
  padding: 20px;
  width: 350px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -350px;
  z-index: 999;
  border-left: 1px solid $light-color;
  transition: all 0.5s ease;

  li {
    cursor: pointer;
  }

  .template-title {
    border-bottom: 1px solid $light-color;
    padding-bottom: 15px;
  }

  ;

  h5 {
    padding-top: 20px;
    padding-bottom: 15px;
  }

  .template-layout {
    ul {
      display: flex;
      align-items: center;

      li {
        width: 70px;
        height: 50px;
        display: flex;
        align-items: center;
        background-color: $white;
        margin-right: 10px;

        &.active {
          .sidebar-content {
            position: relative;

            &:before {
              content: "\e64c";
              font-family: themify;
              position: absolute;
              font-weight: 900;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .sidebar {
          width: 30%;
          height: 100%;
          background-color: $light-color;
          margin-right: 5px;
          border-radius: 5px;
        }

        .sidebar-content {
          background-color: $light-color;
          width: 70%;
          height: 100%;
          border-radius: 5px;
        }

        &:nth-child(2) {
          .sidebar {
            background-color: $black1;
          }
        }

        &:nth-child(3) {

          .sidebar,
          .sidebar-content {
            background-color: $black1;
          }
        }

        &:last-child {

          .sidebar,
          .sidebar-content {
            background-image: linear-gradient(45deg, #3a62b8, #ff803c);
          }
        }
      }
    }
  }

  .chat-wallpaper {
    .wallpaper {
      margin-top: 0;

      li {
        position: relative;
        width: 47px;
        height: 47px;
        border-radius: 5px;
        margin: 2px;
        background-size: unset !important;

        &.active {
          &:before {
            content: "\e64c";
            font-family: themify;
            position: absolute;
            font-weight: 900;
            font-size: 20px;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .templates-content {
    ul {
      li {
        display: inline-block;
        width: 68px;
        height: 60px;
        background-color: $light-color;
        margin-right: 5px;
        border-radius: 5px;
        position: relative;

        &.active {
          &:before {
            content: "\e64c";
            font-family: themify;
            position: absolute;
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            font-weight: 900;
            font-size: 20px;
          }
        }
      }
    }
  }

  .sidebar-setting {
    ul {
      display: flex;

      li {
        width: 144px;
        height: 70px;
        display: flex;
        align-items: center;
        margin-right: 15px;

        &.active {
          .sidebar-content {

            &:before {
              content: "\e64c";
              font-family: themify;
              position: absolute;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 900;
              font-size: 20px;
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }

        .sm-sidebar {
          background-color: $light-color;
          margin-right: 3px;
          width: 10%;
          height: 100%;
        }

        .sidebar {
          background-color: $light-color;
          width: 20%;
          height: 100%;
          border-radius: 5px;
          margin-right: 3px;
        }

        .sidebar-content {
          background-color: $light-color;
          width: 80%;
          height: 100%;
          border-radius: 5px;
          position: relative;

        }
      }
    }
  }
}

.contact-chat>ul>li .contact-name .msg-box {
  margin-bottom: 10px;
  position: relative;
}

.add-popup {
  .close {
    width: 35px;
    height: 35px;
    background: $white;
    border-radius: 50px;
    position: absolute;
    right: 24px;
    top: 19px;
    opacity: 1;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    span {
      color: $primary-color;
      font-size: 32px;
    }
  }

  .modal-header,
  .modal-footer {
    padding: 15px 20px;
  }

  .modal-header,
  .modal-footer {
    .modal-title {
      color: $white;
      text-transform: capitalize;
      font-weight: 700;
      font-size: calc(16px + (28 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .modal-header {
    border-bottom-color: $border-color;
    background-color: $primary-color;
    margin: 4px 4px 0;

    i {
      font-size: 30px;
      margin-right: 10px;
    }
  }

  .modal-body {
    h3 {
      margin-bottom: 20px;
      text-transform: capitalize;
    }
  }

  .default-form {
    .form-group {
      .form-control {
        padding: 15px 15px;

        &::placeholder {
          text-transform: capitalize;
        }
      }
    }
  }

  .modal-footer {
    border-top: 1px solid $border-color;

    button {
      padding: 8px 28px;
      margin: 0;
    }
  }
}

.button-default {
  transition: 0.25s ease-out 0.1s color;
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  outline: none;
  position: relative;
}

.button-default {
  transition: 0.25s ease-out 0.1s color;
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  outline: none;
  position: relative;
}

// todo main modal

.todo-main-modal {
  .modal-body {
    padding-bottom: 0;

    .default-form {
      .todo-task {
        h5 {
          padding-bottom: 10px;
        }
      }

      .form-group {
        &+.form-group {
          display: flex;
          margin-top: unset;

          input {
            height: 45px;
            border-radius: 15px;
            background-color: $white;
            border: none;
            margin-left: 10px;
          }
        }
      }
    }

    .dropdown {
      &.currency {
        width: 50%;
      }
    }
  }
}

.create-todo-main-modal {
  .modal-body {
    padding-bottom: 0;

    .default-form {
      .todo-task {
        h5 {
          margin-bottom: 15px;
        }
      }

      .form-group {
        display: flex;
        align-items: center;

        input {
          margin-left: 10px;
        }
      }

      .dropdown {
        &.currency {
          width: 30%;
        }
      }
    }
  }
}

.datepickers-container {
  z-index: 9999;
}