.dark {
    background-color: $dark;
    $dark-border: $light;

    .for-light {
        display: none;
        margin: 0;
    }

    .for-dark {
        display: block;
        margin: 0;
    }

    .login-page {
        .left-page {
            &::before {
                background-color: rgba($dark, 0.4);
            }
        }
    }

    .counter-box-second {
        &::before {
            background-color: rgba(2, 13, 11, .8) !important;
        }

        .counter-box {
            .counter-no {
                color: rgba($white, 1) !important;
            }
        }
    }

    .landing-header {
        .dropdown-menu {
            .nav-link {
                span {
                    color: rgba($white, 1);
                }
            }
        }
    }

    .login-page {
        .left-page {
            background-color: rgba($dark, 1);
        }
    }

    .login-page {
        .left-page {
            .form1 label {
                color: rgba($white, 0.7);
            }
        }
    }

    .login-page {
        .left-page {
            .form1 {
                .form-select {
                    filter: invert(1);
                }
            }
        }
    }

    .downlaod {
        .footer-title {
            h2 {
                color: rgba($white, 0.8);
            }
        }
    }

    .default-form {
        .form-group {
            label {
                color: rgba($white, 0.7);
            }
        }
    }

    .chatzy-container {
        .chatzy-left-sidebar {

            h1,
            h2,
            h3,
            h4 {
                color: $white !important;
            }

            h5,
            h6,
            p,
            span {
                color: $muted-font;
            }

            select {
                color: $white;
            }

            .contact-list-tab {
                color: $white;
                background-color: $dark;
            }

            .settings-tab {
                color: $white;
                background-color: $dark;
            }

            .template-tab {
                .nav-tabs {
                    .nav-item {
                        .nav-link {
                            background-color: $light ;
                            color: rgba($white, 0.8) !important;

                            svg {
                                fill: rgba($white, 0.8) !important;
                            }

                            &.active {
                                background-color: $primary-color !important;
                            }
                        }
                    }
                }
            }

            .status-tab {
                color: $white;
                background-color: $dark;

                .my-status-box {
                    border-bottom: 1px solid $dark-border;
                }

                .contact-status-box {
                    h3 {
                        background-color: $light;
                    }

                    .status-content {
                        border-bottom: 1px solid $dark-border;

                        ul {
                            li {
                                .img-status {
                                    border: 2px solid $dark;
                                }
                            }
                        }

                        h5 {
                            color: $white;
                        }
                    }

                    .accordion {
                        .accordion-button {
                            color: rgba($white, 1);

                            &::after {
                                filter: contrast(0.1);
                            }
                        }

                        .accordion-item {
                            .accordion-collapse {
                                >.status-content {
                                    .user-status {
                                        &::before {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .recent-default {
                color: $white;
                background-color: $dark;

                .recent {
                    color: $white;
                    background-color: $dark;

                    .recent-slider {
                        .recent-box {
                            .recent-profile {
                                border: 3px solid $dark;

                                .h6 {
                                    color: rgba($white, 0.8) !important;
                                }
                            }
                        }
                    }
                }
            }

            .icon-btn {
                &.btn-outline-light {
                    color: $white !important;
                    background-color: $light !important;
                }
            }
        }

        .main-nav {
            border-right: 1px solid $dark-border;
            display: unset !important;

            .logo-warpper {
                border-bottom: 1px solid $dark-border;
            }
        }

        .chatzy-main {
            background-color: $light;

            .chat-content {
                background-color: $light-dark;
            }

            h5,
            h6 {
                color: $white;
            }

            .messages {
                color: $white;
                background-color: transparent;

                .contact-details {
                    color: $white;
                    background-color: $light-dark;
                    box-shadow: 0 -55px 0px 0px $light;

                    .left {
                        &.d-flex {
                            .flex-grow-1 {
                                h3 {
                                    color: $white;
                                }

                                &::before {
                                    background-color: rgba($white, 1);
                                }
                            }
                        }
                    }
                }

            }

            .message-input {
                background-color: $dark;

                &:after {
                    background-color: $light-dark;
                }

                input {

                    background-color: transparent;
                }
            }

            .switchery {
                background-color: rgba($light, 1) !important;
            }
        }
    }

    .custom-scroll {
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px $light;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba($dark, 1);
        }
    }

    .chatzy-container {
        .chatzy-left-sidebar {
            .recent-default {
                .recent {
                    .recent-slider {
                        .recent-box {
                            .recent-profile {
                                h6 {
                                    color: $white !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .accordion-item {
        background-color: transparent;
    }

    .template-according {
        .card {
            a {
                color: rgba($white, 1);
            }
        }
    }

    ul {
        &.chat-cont-setting {
            background-color: $light;
            box-shadow: 0 0 10px 5px rgba($gray1 , 0.1);
        }
    }

    .collapse-block {
        .block-title {
            &::after {
                color: rgba($white, 1);
            }
        }
    }

    .default-form {
        .form-group {
            h5 {
                color: rgba($white, 1);
            }
        }
    }

    .icon-btn {
        &.btn-outline-light {
            background-color: rgba($light, 1) !important;
            color: rgba($white, 1) !important;
        }
    }

    .msg-setting-main {
        .badge.badge-img {
            background-color: $dark !important;
        }

        .msg-dropdown-main {
            .msg-dropdown {
                background-color: $light;
                border-color: $light;

                ul {
                    li {
                        a {
                            color: rgba($white, 1);

                            i {
                                color: rgba($white, 1);
                            }
                        }
                    }
                }
            }
        }
    }

    .dropdown-menu {
        background-color: $dark;
        color: rgba($white, 1);

        li {
            color: rgba($white, 1);
        }
    }


    span {
        color: rgba($white, 1);
    }

    .dropdown {
        .dropdown-menu {
            .dropdown-divider {
                background-color: rgba($light, 1);
                color: rgba($white, 1);
            }
        }
    }

    .add-popup .modal-footer {
        border-top: 1px solid rgba($white, 0.2);
    }

    .chat-main {
        li {
            &.active {
                color: $white;
                background-color: $light;
            }
        }
    }

    .switch-list {
        li {
            span {
                background-color: rgba($light, 1) !important;
            }
        }
    }

    .form-control {
        background-color: $dark;
        color: rgba($white, 0.8);

        &::placeholder {
            color: rgba($white, 0.5);
        }
    }

    .setting-block {
        border-top: 1px solid $dark-border;

        .block {
            background-color: $dark;
            border-top: 1px solid $dark-border;

            .help {
                >li {
                    border-top: 1px dashed $dark-border;

                    a {
                        color: rgba($white, 1);
                    }
                }
            }
        }
    }

    .integratin {
        li {
            border-bottom: 1px solid $dark-border;
        }
    }

    .search2 {
        border-top: 1px solid $dark-border;

        >div {
            color: $white;
            background-color: $light;
        }
    }

    .group-main {
        li {
            &.active {
                color: $white;
                background-color: $light;
            }
        }

        ul {
            &.grop-icon {
                li {
                    border: 2px solid $dark-border;
                    color: $white;
                }
            }
        }
    }

    .groupuser {
        .gr-chat-friend-toggle {
            .gr-chat-frind-content {
                color: $white;
                background-color: $dark;

                &::before {
                    border-top: 12px solid $dark;
                }
            }

            .add-grbtn {
                &.icon-btn {
                    border: 2px dashed $dark-border !important;
                }
            }
        }
    }

    .msg-box {
        >li {
            h5 {
                background-color: $dark;
            }
        }
    }

    .btn-light {
        color: $white !important;
        background-color: $light;
    }

    .chat-main {
        li {
            .chat-box {
                .details {
                    h4 {
                        color: rgba($white, 1);
                    }
                }
            }
        }
    }

    .template-tab {
        .nav-tabs {
            .nav-item {
                .nav-link {
                    background-color: $light;
                    color: $white !important;
                }
            }
        }
    }

    .groupuser {
        h4 {
            color: $white;
        }

        .gr-chat-friend-toggle {
            .add-grbtn {
                &.icon-btn svg {
                    color: rgba($white, 0.8);
                }
            }
        }
    }

    .group-main {
        .group-box {
            .details {
                h3 {
                    color: $white;
                }
            }
        }
    }

    .right-chat-content {
        h5 {
            color: $white;
            border-top-color: $light;
        }
    }

    .contact-chat {
        >ul {
            >li {
                &.replies {
                    .msg-box {
                        li {
                            >h5 {
                                color: $white;
                            }
                        }
                    }
                }
            }

            &.str-msg {
                li {
                    .msg-box {
                        >li {
                            >h5 {
                                background-color: $light;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    .msg-setting-main {
        .msg-dropdown-main {
            .msg-setting {
                background-color: $dark !important;
                color: $white !important;
            }
        }
    }

    .emojis-main {
        .emojis-contain {
            background-color: $light;

            .emojis-sub-contain {
                &::before {
                    border-top: 12px solid $dark;
                }
            }
        }

        .contact-poll {
            .contact-poll-content {
                background-color: $light;

                ul {
                    li {
                        a {
                            color: $white;
                        }
                    }
                }

                &::before {
                    border-top: 12px solid $light;
                }
            }
        }
    }

    .add-contact-modal {

        h3,
        h5 {
            color: $white;
        }

        .modal-content {
            background-color: $dark;

            .modal-header {
                background-color: $light;
                border-bottom: 1px solid $dark-border;
            }

            .modal-body {
                .form-group {

                    textarea {
                        background-color: $light;
                        color: $white;

                        &::placeholder {
                            color: $white;
                        }
                    }

                    select {
                        background-color: $light;
                        color: $white;
                    }

                    input {
                        background-color: $light;

                        &::placeholder {
                            color: $white;
                        }
                    }

                    .post-poll {
                        background-color: rgba($light, 1);
                    }
                }
            }
        }

        .modal-dialog {
            &::before {
                border-bottom: 10px solid $dark;
            }
        }
    }

    .accordion-button {
        background-color: transparent !important;
    }

    .chat-friend-toggle {
        .chat-frind-content {
            background-color: $light;

            ul {
                li {
                    &:first-child {
                        a {
                            svg {
                                stroke: rgba($white, 0.9) !important;
                            }
                        }
                    }

                    &:last-child {
                        a {
                            svg {
                                fill: rgba($white, 0.9) !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .search-form {
        .form-group {
            input {
                border: 1px solid $dark-border;
                background-color: $light;
                color: rgba($white, 0.8);
            }
        }

        &::before {
            color: $white;
        }
    }

    .template-according {
        .card {
            .card-header {
                background-color: $light;
            }

            .card-body {
                background-color: $light;
            }

        }
    }

    .card {
        background-color: $dark;
    }

    .change-number {
        .form-control {
            border-color: $dark-border;
        }
    }

    .rightchat {
        &.animat-rate {
            .bg_circle {
                >div {
                    border: 3px solid #42434a;
                }
            }

            .cross1 {
                background: #42434a;

                &::after {
                    background: #42434a;
                }
            }

            .cross2 {
                background: #42434a;

                &::after {
                    background: #42434a;
                }
            }

            .cross {
                background: #42434a;

                &::after {
                    background: #42434a;
                }
            }

            .dot1 {
                background-color: #42434a;
            }
        }
    }

    .left-page {
        background-color: rgba($dark, 0.8);
    }

    .login-page {
        .login-content {
            background-color: $light !important;
        }
    }

    // sign up page
    .login-page2 {
        .login-content-main {
            .login-content {
                background-color: $light;
                color: $white;

                h3 {
                    color: $white;
                }

                h4 {
                    color: $white;
                }

                .form1 {
                    .form-group {

                        .col-form-label {
                            color: $white;
                        }

                        .form-control {
                            background-color: $light;
                        }
                    }
                }

                .form2 {
                    .form-group {
                        .col-form-label {
                            color: $white;
                        }

                        .form-control {
                            background-color: $light-dark;
                            border: 1px solid $dark-border;

                            &::placeholder {
                                color: $white;
                            }
                        }
                    }
                }

                .login-content-header {
                    img {
                        z-index: 140;
                    }
                }
            }
        }
    }

    // login page

    .login-page1 {
        .left-page {
            background-color: $dark;

            h3 {
                color: $white;
            }

            h4 {
                color: $white;
            }
        }
    }

    .login-page {
        .login-bg-img {
            background-color: $light-dark;
        }
    }

    .faq-section {
        .accordion {
            &.template-accordion {
                .card {
                    .card-header button {
                        background-color: $light-dark !important;
                    }
                }
            }
        }
    }

    .faq-img {
        background-color: $light;
    }

    .login-content {
        background-color: $dark;
        color: $white;

        h3 {
            color: $white;
        }

        h4 {
            color: $white;
        }

        .form1 {
            .form-group {

                .col-form-label {
                    color: $white;
                }

                .form-control,
                .form-select {
                    background-color: $light-dark;
                    border: 1px solid $dark-border;

                    &::placeholder {
                        color: $white;
                    }
                }

                .form-check {
                    h6 {
                        color: $white;
                    }
                }
            }
        }

        .form2 {
            .form-group {
                .col-form-label {
                    color: $white;
                }

                .form-control {
                    background-color: $light;
                    border: 1px solid $dark-border;

                    &::placeholder {
                        color: $white;
                    }
                }

                .form-check {
                    h6 {
                        color: $white;
                    }
                }
            }
        }

        .login-content-header {
            img {
                z-index: 140;
            }
        }

        .line {

            h6 {
                color: $white;
            }
        }
    }

    .right-login {
        background-color: $light;

        &.animat-rate {
            .bg_circle {
                >div {
                    border: 3px solid #42434a;
                }
            }

            .cross {
                background: #42434a;

                &::after {
                    background: #42434a;
                }
            }

            .cross1 {
                background: #42434a;

                &::after {
                    background: #42434a;
                }
            }

            .cross2 {
                background: #42434a;

                &::after {
                    background: #42434a;
                }
            }

            .dot {
                background-color: #42434a
            }

            .dot1 {
                background-color: #42434a
            }
        }

        .maincircle {
            background-image: linear-gradient(#191d1f, #191d1fb3, #191d1fb3);
        }

        .top-circle {
            background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
        }

        .center-circle {
            background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
        }

        .bottom-circle {
            background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
        }

        .bottom-circle1 {
            background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
        }

        .right-circle {
            background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
        }

        .right-circle1 {
            background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
        }
    }

    .login-page2 {
        background-color: $dark;

        &.animat-rate {
            .bg_circle {
                >div {
                    border: 3px solid #42434a;
                }
            }

            .cross {
                background: #42434a;

                &::after {
                    background: #42434a;
                }
            }

            .cross1 {
                background: #42434a;

                &::after {
                    background: #42434a;
                }
            }

            .cross2 {
                background: #42434a;

                &::after {
                    background: #42434a;
                }
            }

            .maincircle {
                background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
            }

            .top-circle {
                background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
            }

            .center-circle {
                background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
            }

            .bottom-circle {
                background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
            }

            .bottom-circle1 {
                background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
            }

            .right-circle {
                background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
            }

            .right-circle1 {
                background-image: linear-gradient(#191d1f, #191d1f73, #191d1f26);
            }

            .quarterCircle {
                background-image: linear-gradient(#191d1f);
            }

            .dot {
                background-color: #42434a
            }

            .dot1 {
                background-color: #42434a
            }
        }

    }

    .counter-box-second {
        background-color: $light;

        .counter-box {
            h6 {
                color: $white;
            }

            span {
                color: $white;
            }
        }
    }

    // error 404 page
    .error-main {
        .error-contain {
            h1 {
                color: $white;
            }

            h2,
            h4 {
                color: $white;
            }
        }
    }

    .tab-content {
        >.tab-pane {
            color: $white;
        }
    }

    .testimonial-box {
        &::before {
            background-color: rgba($dark, 0.8);
        }

        .test-img {
            background-color: $dark;
        }
    }

    .counter-sec {
        .counter-title {
            .title-line {
                color: $white;
            }
        }
    }

    .counter-box-second {
        &::before {
            background-color: rgba($dark, 0.8);
        }
    }

    .team {

        h2,
        h3,
        h4,
        p {
            color: $white;
        }

        h2 {
            border-bottom: 1px solid $dark-border;
        }

        .team-content {
            border: 1px solid $dark-border;
        }
    }

    .testimonial-box {
        background-color: $light;

        .test-contain {

            h3,
            h4,
            h6 {
                color: $white;
            }
        }
    }

    .btn-link {
        background-color: $light;
    }

    .fa-search {
        &:before {
            color: $white;
        }
    }

    // landing page
    .app-design-main {
        .contant-box {

            h2,
            h4 {
                color: $white;
            }
        }
    }

    .chatzy-main {
        .chatzy-contain {

            h1,
            h3,
            h4,
            span {
                color: $white;
            }

            ul {
                &.detial-price {
                    li {
                        color: $white;
                    }
                }
            }
        }
    }

    .subscribe-content {

        h1,
        h4,
        span {
            color: $white;
        }
    }

    // index page css
    .content-title {
        h2 {
            color: rgba($white, 1);
        }
    }

    // header_
    .navbar-light {
        .navbar-nav {
            .active {
                >.nav-link {
                    color: $primary-color !important;
                }
            }

            .nav-link {
                color: rgba(242, 239, 239, 1) !important;
            }
        }
    }

    header .landing-header .navbar-collapse ul li a {
        color: rgba(242, 239, 239, 1) !important;
    }

    .landing-header .dropdown-menu {
        border: 1px solid $dark-border;
    }

    header .landing-header.fixed .main-menu .navbar-nav>li>a {
        color: rgba(242, 239, 239, 1) !important;
    }

    .login-page2 .login-content-main .login-content2 {
        background-color: $light;
    }

    .testimonial-box .test-contain ul.test-icon li {
        background-color: rgba($dark, 1);
    }

    .testimonial-slider .owl-nav button.owl-prev,
    .testimonial-slider .owl-nav button.owl-next {
        background-color: rgba($light, 1);
    }

    .search-form .form-group .icon-close svg {
        stroke: rgba($white, 1);
    }

    // footer
    footer {
        background-color: $light;
        border-top: 1px solid $dark-border;

        h3,
        p {
            color: $white;
        }

        .links {
            li {
                a {
                    color: $white;
                }
            }
        }

        .copyright {
            p {
                color: $white;
            }
        }
    }

    .introjs-tooltipReferenceLayer {
        .introjs-tooltip {
            background-color: $light;
            color: $white;
        }

        .introjs-arrow.left {
            border-right-color: $light;
        }
    }

    .video-box {
        background-color: $light;

        .info-block {
            .star-ratings {
                ul {
                    li {
                        color: rgba($white, 0.8);
                    }
                }
            }
        }
    }

    .player-box {

        background-color: transparent;
        border: none;

        .main-wrapper {
            background-color: $light;

            .song-wrapper {
                .song-info {
                    .song-info-title {
                        color: $white;
                    }
                }
            }
        }
    }

}

/***Dark sidebar  ****/

/**Header**/
.dark-sidebar {
    .chat-main li .chat-box .date-status .ti-pin2 {
        &:before {
            color: $white;
        }
    }

    .btn-light {

        &:focus,
        &:active,
        &.active {
            box-shadow: 0 0 3px 2px $gray;
        }
    }

    .chatzy-container {
        .message-input {
            .emojis-main {
                input {
                    background-color: transparent !important;
                }
            }
        }
    }

    .chatzy-container {
        .chatzy-left-sidebar {
            .recent-default {
                background-color: $dark;

                .recent {
                    background-color: $dark;

                    .recent-slider {
                        .recent-box {
                            .recent-profile {
                                border: 3px solid $dark;

                                h6 {
                                    color: $white !important;
                                }
                            }
                        }
                    }
                }
            }

            .contact-list-tab,
            .settings-tab {
                background-color: $dark;
            }

            .status-tab {
                background-color: $dark;

                .contact-status-box {
                    h3 {
                        background-color: $light;
                        color: $white;
                    }

                    .status-content {
                        border-bottom: 1px solid $dark-border;

                        ul {
                            li {
                                .img-status {
                                    border: 2px solid $dark;
                                }

                                h4 {
                                    color: $white;
                                }
                            }
                        }
                    }

                    .accordion {
                        .accordion-item {
                            background-color: transparent;

                            .accordion-header {
                                button {
                                    color: rgba($white, 1);
                                }
                            }
                        }
                    }
                }

                .my-status-box {
                    border-bottom: 1px solid $dark-border;
                }
            }

            h1,
            h2,
            h3,
            h4 {
                color: $white;
            }

            h5,
            h6,
            p,
            span {
                color: rgba($white, 0.6);
            }

            select {
                color: $white;
            }

            .icon-btn {
                &.btn-outline-light {
                    background-color: $light !important;
                    color: $white !important;
                }
            }

            .search-form {
                input {
                    background-color: $dark;
                    border: 1px solid $dark-border;
                }

                &::before {
                    color: $white;
                }
            }

            .search2 {
                border-top: 1px solid $dark-border;

                >div {
                    background-color: $light;
                }
            }

            .chat-main {
                li {
                    &.active {
                        background-color: $light;
                        border-left: 4px solid $dark-border;
                    }
                }
            }

            .setting-block {
                .block {
                    background-color: $dark;
                }
            }

            .template-according {
                .card {
                    background-color: $dark;

                    .card-header {
                        background-color: $light;
                    }

                    .card-body {
                        background-color: $light;
                    }
                }
            }

            .integratin {
                li {
                    border-bottom: 1px solid $dark-border;
                }
            }

            .setting-block {
                border-top: 1px solid $dark-border;

                .block {
                    border-top: 1px solid $dark-border;

                    .help {
                        >li {
                            border-top: 1px dashed $dark-border;

                            a {
                                color: rgba($white, 1);
                            }
                        }
                    }

                    a {
                        color: $primary-color;
                    }
                }
            }

            .template-tab {
                .nav-tabs {
                    .nav-item {
                        .nav-link {
                            background-color: $light !important;
                            color: $white !important;

                            &.active {
                                color: $white !important;
                                background-color: rgba($primary-color, 1) !important;
                            }
                        }
                    }
                }
            }

            .group-main {
                li {
                    &.active {
                        background-color: $light;
                        border-left: 4px solid $dark-border;
                    }
                }
            }
        }

        .main-nav {
            border-right: 1px solid $dark-border;

            .logo-warpper {
                border-bottom: 1px solid $dark-border;
            }

            .btn-light {
                background-color: $light !important;
                color: $white !important;
            }
        }

        .change-number {
            .form-control {
                border-color: $dark-border;
            }
        }

        .form-control {
            background-color: $dark;
        }
    }

    .template-tab {
        &.tab-sm {
            >.nav-tabs {
                >.nav-item {
                    .nav-link {
                        svg {
                            fill: rgba($white, 0.7) !important;
                        }
                    }
                }
            }
        }
    }

    #group {
        .search2 {
            input {
                background-color: transparent;
            }
        }
    }



    //responsive
    @media (max-width: 1367px) {

        //header
        header {
            .landing-header {
                &.fixed {
                    .main-menu {
                        .navbar-nav {
                            >li {
                                >a {
                                    color: $white !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {

        //header
        .inner-page {
            header {
                .landing-header {
                    .navbar-collapse {
                        background-color: $dark;
                        border: 1px solid $dark-border;
                    }
                }
            }
        }
    }
}