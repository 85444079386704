.chatzy-main{
	.messages{
		padding: 0;
		height: calc(100vh - 0px);
		min-height: calc(100vh - 0px);
		overflow: auto;
		overflow-x: hidden;
		overflow-y: auto;
		background-blend-mode: overlay;
		top: 0;
		background-color: rgba($primary-color , 0.1);
		display: none;
		animation: fadeInLeft 300ms ease-in-out;
		background-image: url(../../images/wallpaper/1.png);
		background-size: cover;
		background-repeat: no-repeat;
		&.active{
			display: block;
			animation: fadeInLeft 300ms ease-in-out;
		}

		.contact-details{

      .contact-details-overlay {
        background-color: rgba($primary-color , 0.1);
        box-shadow: 0 -55px 0px 0px rgba(rgba($primary-color , 0.1), 1);
        border-bottom: 1px solid rgba($muted-font , 0.1);
      }

			.left{
				&.d-flex{
					.media-right{
						.mute{
							i{
								&:after{
									background-color: $dark-color;
								}
							}
						}
					}
				}
			}

		}
	}

	.message-input{
		position: fixed;
		background-color: $white;
		width: calc(100% - 465px);
		margin: 0 20px;
		transition: all 0.5s;
		padding: 16px 15px;
		z-index: 9;
		bottom: 30px;
		border-radius: $radious15;

    /*
		&::after{
			content: '';
			position: absolute;
			background-color: $primary-light;
			height: 100%;
			width: 100%;
			bottom: -74px;
			left: 0;
			z-index: -1;
		}
    */
    
		input{
			width: calc(100% - 115px);
			border: 0;
			font-size: 16px;
			color: $muted-font;
			font-family: $font-1;
			&:focus{
				box-shadow: none;
				outline-color: transparent !important;
			}
		}
		a{
			svg {
				height: 22px;
				width: 22px;
				stroke: $primary-color;
			}
		}
		button {
			svg {
				width: 22px;
				height: 22px;
			}
		}
		
	}
	.chat-content{
		position: relative;
	}

  .chat-content{
		opacity: 0;
		&.active{
			opacity: 1;
			z-index: 3;
			animation: fadeInLeft 300ms ease-in-out;
		}
	}
}
.menu-active{
	.chatzy-main{
		.message-input {
			width: calc(100% - 850px);
			transition: all 0.9s;
		}
		.messages {
			.contact-details {
				width: 50.6vw !important;
				transition: all 0.9s ease;
			}
		}
	}
}
