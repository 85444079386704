  // app design start //
  .light-bg{
    background-color: rgba($primary-color, 0.05);
  }

  // chatzy start /
  .chatzy-main {
    position: relative;
    overflow: hidden;
    .chatzy-contain{
      display: flex;
      align-items: center;
      height: 100%;
      img{
        margin-top: 20px;
      }
      .chatzy-logo {
        margin-bottom: 35px;
      }
      h3 {
        font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
        color: $dark-color;
        font-family: $font-1;
        font-weight: 800;
        margin-bottom: 40px;
        span {
          font-weight: 600;
          font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
        }
      }
      h1 {
        font-size: calc(18px + (48 - 18) * ((100vw - 320px) / (1920 - 320)));
        color: $dark-color;
        font-family:$font-1;
        font-weight: 800;
        margin-bottom: 50px;
        width:  560px;
      }
      h4 {
        font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: $dark-color;
        font-family: $font-1;
        font-weight: 400;
        margin-bottom: 40px;
        line-height: 1.6;
      }
      ul.detial-price {
        li {
          margin-bottom: 15px;
          font-size: 14px;
          color: $black1;
          font-family: $font-1;
          font-weight: 300;
          &:last-child {
            margin-bottom: 0;
          }
          i {
            color: $primary-color;
            margin-right: 20px;
          }
        }
      } 
    }
    .chatzy-back-block {
      .chit-chat1 {
        position: absolute;
        top: 28%;
        left: 16%;
        z-index: -1;
        animation: animatow 30s linear infinite;
      }
      .chit-chat2 {
        position: absolute;
        top: 33%;
        right: 8%;
        z-index: -1;
        animation: animatow 30s linear infinite;
      }
      .chit-chat3 {
        position: absolute;
        top: 54%;
        left: 33%;
        z-index: -1;
        animation: slidemain 50s linear infinite;
      }
    }
  }


  .button-bg-color {
    a {
      &:nth-child(2) {
        &:hover {
          background-color: $success-color !important;
        }
      }
      &:nth-child(3) {
        &:hover {
          background-color: $danger-color !important;
        }
      }
      &:nth-child(4) {
        &:hover {
          background-color: $light-color !important;
          color: $dark-color !important;
        }
      }
      &:nth-child(5) {
        &:hover {
          background-color: $warning-color !important;
        }
      }
    }
  }


   /*=====================
    animation 
  ==========================*/
  @-webkit-keyframes slidemain {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
