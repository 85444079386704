// msg list
.msg-box{
	&.bg-white{
		border-radius: 12px 12px 12px 0px;
	}
	> li{
		> h5 {
			display: inline-block;
			padding: 16px 25px;
			background-color: $white;
			color: $dark-color;
			border-radius: 0 25px 25px 0;
			font-weight: 600;
		}

		&:first-child{
			h5{
				border-radius: 20px;
			}
		}
		&:last-child{
			h5 {
				border-radius: 20px 20px 20px 0;
			}
		}
		&+li{
			margin-top: 12px;
			position: relative;
		}
	}
}
.contact-chat{
	padding: $contant-spacing 30px 150px;
	margin-top: 100px;
	> ul{
		&.str-msg{
			h5{
				font-size: 13px;
			}
			h6{
				font-size: 9px;
			}
			li{
				padding-left: 0 !important;
				width: 100%;
				.d-flex{
					.profile{
						margin-left: 0.7rem !important;
					}
				}
				.contact-name{
					.msg-box{
						margin-top: 5px;
					}
				}
				.msg-box > li > h5{
					padding: 8px 25px;
					font-size: 12px;
					line-height: 1.6;
					color: $dark-color;
					background-color: $border-color1;
				}
				.profile{
					width: 30px;
					height: 30px;
				}
			}
		}
		&.chatappend{
			display: flex;
			flex-direction: column;
			> li{
				&:last-child{
					.msg-box{
						> li{
							.msg-dropdown{								
								bottom: 50px !important;
								top: unset !important;
							}
						}
					}
				}				
			}
			.last{
				order: 999;
			}
		}
		> li{
			&.sent{
				.contact-name{
					.msg-box{
						.msg-setting-main{
							.badge.badge-img{
								bottom: -10px;
								left: 12px;
								background-color: $white;
							}
						}
					}
				}
			}
			&.sent,&.replies{
				width: 80%;
			}
			&.replies{
				margin-left: auto;
				text-align: right;

				.msg-box{
					li{
						display: inline-flex;
						&:first-child{
							h5{
								border-radius: 20px;
							}
						}
						&:last-child{
							display: flex;
							flex-direction: row-reverse;
							align-items: center;
							margin-left: auto;
							h5{
								border-radius: 20px 20px 0px 20px;
							}
						}
						> h5{
							background-color: $primary-color;
							color: $white;
						}
						.badge{
							margin-left: 0 !important;
							margin-right: 0.5rem !important;
						}
					}
				}
				.font-danger{
					svg {
						width: 20px;
						height: 20px;
						margin: 0 6px;
						vertical-align: -5px;
					}
				}
				.d-flex{
					flex-direction: row-reverse;
					.profile{
						margin-left: 1.5rem !important;
						margin-right: 0 !important;
					}
					.contact-name{
						h6{
							svg {	
								width: 20px;
								height: 20px;
								margin: 0 6px;
								vertical-align: -5px;
							}
						}
					}
				}
				.msg-setting-main  {
					max-width: 80%;
					.msg-dropdown-main  {
						.msg-setting {
							margin-right: 10px;
							margin-left: unset;
						}
					}
				}
			}
			&+li{
				margin-top: 10px;
			}
			.contact-name{
				.msg-box{
					img{
						width: 130px;
					}
				}
				h5{
					display: inline-block;
					padding-right: 20px;
					line-height: 1.4;
					// max-width: 290px;
					text-overflow:ellipsis;
					//overflow:hidden;
					white-space: normal;
				}
				h6{
					display: inline-block;
				}
			}
		}
	}
}
.msg-setting-main {
	max-width: 80%;
	display: flex;
	align-items: center;
	width: fit-content;
	transition: all 0.5s ease;
	.msg-dropdown-main {
		position: relative;
		display: none;
		transition: all 0.5s ease;
		.msg-setting {
			background-color: $white;
			width: 38px;
			height: 38px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 10px;
		}
		.msg-dropdown {
			background-color: $white;
			border: 2px solid $border-color;
			padding: 15px;
			border-radius: 15px;
			position: absolute;
			top: 50px;
			left: 0;
			z-index: 1;
			text-align: left;
			display: none;
			transition: all 0.5s ease;
			ul {
				li {
					font-size: 14px;
					font-weight: 600;
					text-transform: capitalize;
					margin-bottom: 10px;
					&:last-child {
						margin-bottom: 0;
					}
					a {
						color: $black1;
						width: 100%;
						i {
							font-size: 16px;
							display: inline;
							margin-right: 15px;
						}
					}
				}
			}
		}
	}
	.icon-btns{
		svg{
			height: 35px;
			width: 35px;
		}
	}
	&:hover {
		.msg-dropdown-main {
			display: inline-block;
			order: 0;
		}
	}
}