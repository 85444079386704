//****lOGIN-2*****// 

.login-page2 {
  min-height: 100vh;
  background-color: $primary-light;
  display: flex;
  align-items: center;

  .login-content-main {
    margin: 0 auto;

    .login-content2 {
      background-color: $white;
      box-shadow: 0px 7px 29px 0px rgba($muted-font, 0.2);
      padding: 23px;
      border-radius: $radious15;
      z-index: 10;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;

      .template-tab {
        .nav-tabs {
          padding-bottom: unset;

          .nav-item {
            .nav-link {
              background-color: $primary-color !important;
              color: $white !important;

              &.active {
                color: $primary-color !important;
                background-color: rgba($primary-color , 0.15) !important;
              }
            }
          }

          li {
            +li {
              margin-left: 15px;
            }
          }
        }
      }
    }

    .login-content {
      background-color: $white;
      border: 3px;
      width: 600px;
      padding: 50px 80px;
      z-index: 10;
      border-radius: 10px;
      box-shadow: 0px 7px 29px 0px rgba($muted-font, 0.2);
      position: relative;

      h3 {
        font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
        font-family: $font-1;
        font-weight: 600;
        padding-bottom: 6px;
      }

      h6 {
        margin-bottom: 35px;
        line-height: 1.4;
        font-family: $font-1;
        font-weight: 400;
      }

      .form2 {
        .form-group {
          margin-bottom: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
          ;

          .col-form-label {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            color: $dark-color;
            font-family: $font-1;
            font-weight: 600;
            padding-top: 0;
            padding-bottom: 15px;
            line-height: 1;
          }

          .buttons {
            .btn {
              padding: 14px 65px;
              color: $white;
              justify-content: center;
            }

            .btn-signup {
              background-color: $dark-color;
              margin-left: 25px;
            }
          }

          .form-check {
            display: flex;
            justify-content: space-between;
            align-items: end;

            h6 {
              color: $dark-color;
              font-family: $font-1;
              font-weight: 500;
            }
          }

          .form-check-input {
            margin-right: -1.25rem;
            margin-left: unset;
          }

          .form-control {
            padding: 13px 20px;
            border-radius: $radious5;
            border: 1px solid rgba(0, 0, 0, 0.10);

            &::placeholder {
              font-size: 15px;
              color: $dark-color;
              font-family: $font-1;
              font-weight: 400;
            }
          }

          .form-check-label {
            font-size: 12px;
            font-family: $font-1;
            font-weight: 500;
            text-transform: capitalize;
          }
        }
      }

      .line {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        margin-top: 50px;

        h6 {
          font-family: $font-1;
          font-weight: 600;
          color: $dark-color;

          &::before {
            display: inline-block;
            content: "";
            border-top: 1px solid #ededed;
            width: 4.5rem;
            margin: 0 1rem;
          }

          &::after {
            display: inline-block;
            content: "";
            border-top: 1px solid #ededed;
            width: 4.5rem;
            margin: 0 1rem;
          }
        }
      }

      .medialogo {
        margin-bottom: 30px;

        ul {
          display: flex;
          justify-content: center;

          li {
            +li {
              margin-left: 20px;
            }
          }
        }

        .btn-facebook {
          background-color: #2c67ce;
          color: $white;
        }
      }

      .termscondition {
        text-align: center;

        h4 {
          span {
            color: $danger-color;
            margin-right: 8px;
          }

          b {
            margin-left: 8px;
            margin-right: 8px;
          }
        }
      }

      .login-content-header {
        padding-bottom: 20px;
        text-align: center;
      }

      .form2 {
        .form-group {
          .buttons {
            margin-bottom: unset;

            @media (max-width: 767px) {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }

    .medialogo {
      display: inline-block;
      left: -4%;
      position: absolute;
      top: 40%;
      margin-bottom: unset;

      li {
        +li {
          margin-left: unset;
          margin-top: 10px;
        }
      }
    }
  }

  .top-circle {
    width: calc(90px + (280 - 90) * ((100vw - 320px) / (1920 - 320)));
    height: calc(90px + (280 - 90) * ((100vw - 320px) / (1920 - 320)));
    top: 11%;
    left: 12%;
  }

  .center-circle {
    background-image: linear-gradient(to left, #cde7fa, #f1f8fe, #f1f8fe);
    left: 6%;
    height: calc(60px + (150 - 60) * ((100vw - 320px) / (1920 - 320)));
    width: calc(60px + (150 - 60) * ((100vw - 320px) / (1920 - 320)));
  }

  .quarterCircle {
    background-image: linear-gradient(to right, #cde7fa, #f1f8fe);
    width: 80px;
  }

  .right-circle1 {
    background-image: linear-gradient(to top, #cde7fa, #f1f8fe, #f1f8fe);
    width: 70px;
    height: 70px;
    top: 33%;
    right: 11%;
  }

  .bottom-circle1 {
    background-image: linear-gradient(#cde7fa, #f1f8fe, #f1f8fe);
    width: 80px;
    height: 80px;
    bottom: 2%;
    right: 35%;
  }

  .right-circle {
    background-image: linear-gradient(to right, #f1f8fe, #f1f8fe, #cde7fa);
    width: calc(50px + (160 - 50) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (160 - 50) * ((100vw - 320px) / (1920 - 320)));
    bottom: 0;
    right: 3%;
  }

  .cloud-logo {
    top: -6%;
    left: -81%;
    height: 5%;
    transform: none;
  }

  .cloud-logo4 {
    bottom: 2%;
  }

  .cloud-logo2 {
    left: -208%;
    height: 13%;
  }

  .cloud-logo5 {
    position: absolute;
    top: 2%;
    animation: homecloud 30s linear infinite;
    height: 10%;
  }
}

@keyframes animateCloud {
  0% {
    margin-left: -700px;
  }

  100% {
    margin-left: 100%;
  }
}

@keyframes homecloud {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(1000px);
  }

  100% {
    transform: translateX(400px);
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(.75);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(.75);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(.75);
  }

  100% {
    transform: scale(.75);
  }
}

@media (max-width:1470px) {
  .login-page1 {
    .right-page {
      width: 60%;
    }
  }

  .login-page2 {
    padding-top: 40px;
    padding-bottom: 40px;
  }


}

@media (max-width:1367px) {
  .login-page2 {
    .login-content-main {
      .login-content {
        padding: 50px;
        position: relative;
      }
    }
  }
}

@media (min-width:992px) and (max-width:1200px) {
  .login-content {
    .line {
      h6 {
        &::before {
          width: 3.5rem;
        }

        &::after {
          width: 3.5rem;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .login-content {

    .form1,
    .form2 {
      .form-group {
        .buttons {
          .btn {
            padding: 12px 38px;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .login-page1 {
    .left-page {
      padding: 30px;
    }
  }

  .login-content {
    width: 430px;

    .form1 {
      .form-group {
        .buttons {
          .btn {
            padding: 14px 32px;
          }
        }
      }
    }

    .form2 {
      .form-group {
        .buttons {
          .btn {
            padding: 14px 50px;
          }
        }
      }
    }
  }

  .right-login {
    .boy-logo {
      left: 29%;
      top: 50%;
    }

    .girl-logo {
      right: 14%;
      top: 27%;
    }

    .has-logo1 {
      left: 12%;
    }

    .login-img {
      width: 65%;
    }
  }

  // login page 1 //
  .login-content {
    h4 {
      margin-bottom: 24px;
    }

    .form1,
    .form2 {
      .form-group {
        .col-form-label {
          padding-bottom: 16px;
        }

        .form-check {
          margin-bottom: 30px;
        }

        .buttons {
          margin-bottom: 30px;
        }
      }
    }

    .line {
      margin-bottom: 30px;
    }

    .medialogo {
      margin-bottom: 30px;
    }
  }
}

@media (max-width:1024px) {
  .login-content {
    width: 350px;
  }
}

@media (max-width: 991px) {
  .right-login {
    .login-img {
      width: 80%;
    }

    .girl-logo {
      width: 35%;
      top: 23%;
      right: 3%;
    }

    .boy-logo {
      left: 21%;
      top: 45%;
      width: 27%;
    }

    .has-logo1 {
      top: 69%;
      left: 2%;
    }
  }

  // login page 1 //
  .login-page1 {
    .left-page {
      width: 50%;
    }

    .right-page {
      width: 50%;
    }
  }
}

@media (max-width: 768px) {
  .login-content {
    width: 340px;

    .line {
      h6 {
        &::before {
          width: 3.5rem;
        }

        &::after {
          width: 3.5rem;
        }
      }
    }
  }

  .right-login {
    .boy-logo {
      left: 23%;
      top: 43%;
      width: 24%;
    }
  }

  .girl-logo {
    right: 11%;
    top: 24%;
  }
}

@media (max-width: 767px) {
  .right-page {
    position: absolute;
    opacity: 0.05;
  }

  .login-content {
    width: auto;
    padding: 50px;

    .login-content-header {
      img {
        height: 40px;
      }
    }

    h4 {
      margin-bottom: 17px;
    }

    .form1,
    .form2 {
      .form-group {
        margin-bottom: 20px;

        .col-form-label {
          padding-bottom: 10px;
        }

        .form-check {
          margin-bottom: 20px;
        }
      }
    }

    .form2 {
      .form-group {
        .buttons {
          .btn {
            padding: 12px 35px;
          }
        }
      }
    }
  }

  // login page 1 //
  .login-page1 {
    .left-page {
      width: 100%;
    }

    .right-page {
      width: 100%;
    }
  }

  // login page //
  .login-page2 {
    .login-content-main {
      .login-content {
        width: 530px;
        padding: 30px;
      }

      .medialogo {
        position: unset;
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;

        li {
          +li {
            margin-top: unset;
            margin-left: 15px;
          }
        }
      }

      .login-content2 {
        .template-tab {
          .nav-tabs {
            .nav-item {
              .nav-link {
                min-width: auto;
                padding: 10px 35px;
              }
            }
          }
        }
      }
    }
  }

  .login-content {
    h3 {
      padding-bottom: 10px;
    }
  }


}

@media (max-width: 600px) {
  .login-page2 {
    .medialogo {
      display: flex;
      position: unset;
      width: 100%;
      justify-content: center;

      li {
        +li {
          margin-top: unset;
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .login-content {
    padding: 30px;

    .form1,
    .form2 {
      .form-group {
        .buttons {
          .btn {
            padding: 12px 25px;
          }
        }

        .form-control {
          padding: 10px;
          line-height: 1;

          &::placeholder {
            font-size: 13px;
          }
        }
      }
    }


    .login-content-header {
      img {
        height: 30px;
      }
    }
  }

  .login-page1 {
    .left-page {
      padding: 0;
    }
  }

  .login-page2 {
    .login-content-main {
      padding: 15px;

      .login-content {
        width: 100%;
      }

      .login-content2 {
        padding: 20px;

        .template-tab {
          .nav-tabs {

            .nav-item {
              margin-right: 0;

              .nav-link {
                padding: 10px 25px;
              }
            }
          }
        }
      }

      .medialogo {
        li {
          .icon-btn {
            width: 30px;
            height: 30px;

            i {
              font-size: 12px;
            }
          }
        }
      }
    }
  }




}

@media (max-width: 360px) {
  .login-content {

    .form1,
    .form2 {
      .form-group {
        .buttons {
          .btn {
            padding: 10px 24px;
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .login-content {
    .line {
      h6 {
        &::before {
          width: 2.5rem;
        }

        &::after {
          width: 2.5rem;
        }
      }
    }
  }
}

.medialogo {
  .btn-facebook {
    background-color: #2c67ce;
    color: $white;
  }
}