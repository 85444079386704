@media(min-width:1680px) {
  .custom-container {
    max-width: 1600px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media(max-width: 1620px) and (min-width: 1368px) {
  header {
    .landing-header {
      &.fixed {
        width: 98%;
      }
    }
  }
}

@media(max-width:1367px) {
  .index-main {
    .template-landing {
      .section-py-space {
        padding-top: 100px;
        padding-bottom: 60px
      }
    }
  }

  // header css//
  header {
    .landing-header {
      &.fixed {
        .main-menu .navbar-nav>li>a {
          color: $dark-color !important;
        }
      }

      .navbar-nav {
        .nav-item {
          &:last-child {
            .dropdown-menu {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }

  // landing slide sectin //
  .slider-block {
    .slider-main {
      height: 100vh;
    }
  }


  // rat us section //
  .rate-us {
    padding-top: 60px !important;
  }
}

@media(max-width:1679px) {
  .home-wrapper {
    .custom-container {
      padding: 0 32px;
    }
  }

  .custom-container {
    max-width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    margin: 0 auto;
  }

  // slider start //
  .slider-block {
    .chat-slide {
      bottom: -13%;
    }

    .inner1 {
      top: 15%;
      left: 25%;
    }

    .inner2 {
      right: -26%;
    }

    .slider-main {
      .slider-contain {
        .ply-main {
          .ply-content {
            padding: 10px;

            .ply-sub-content {
              width: 45px;
              height: 45px;

              i {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  // footer css //
  footer {
    .footer-main {
      .footer-back-block {
        .inner1 {
          left: -60%;
          top: -130%;
        }

        .inner2 {
          right: -60%;
          bottom: -130%;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .home-wrapper {
    padding-top: 8% !important;
  }

  .index-main .trending-features .features-contant h4 {
    width: 90%;
  }
}

@media (max-width:1470px) {
  .index-main {
    .landing-page-header {
      .home-img img {
        &:nth-child(2) {
          width: 100px;
        }

        &:nth-child(3) {
          right: 5%;
          width: 135px;
        }
      }
    }
  }

  // slider css //
  .slider-block {
    .inner2 {
      right: -40%;
    }

    .chat-slide {
      bottom: 0;
    }
  }

  // chit chat css start //
  .chatzy-main {
    .chatzy-contain {
      justify-content: center;

      h1 {
        width: 450px;
      }

      .chatzy-img {
        width: 450px;
      }
    }
  }



  // subscribe upadate css //
  .subscribe-content {
    h1 {
      margin-bottom: 23px;
    }

    h4 {
      margin-top: 18px
    }

    .form-inline {
      display: unset !important;
    }

    button {
      margin-top: 23px;
      display: block;
      margin-left: 0;
    }
  }
}

@media(max-width:1367px) {
  .section-py-space {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section-pt-space {
    padding-top: 60px;
  }

  .section-pb-space {
    padding-bottom: 60px;
  }

  // slider block //

  .slider-block {
    .inner1 {
      display: none;
    }
  }

  // app design css //
  .app-design-main {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .app-design-main {
    .app-hover {
      top: 6%;
    }
  }

  // chatzy css //
  .chatzy-main {
    .chatzy-back-block {
      .chit-chat3 {
        left: 11%;
      }
    }

    .chatzy-contain {
      .chatzy-logo {
        margin-bottom: 22px;
      }

      h3 {
        margin-bottom: 20px;
      }

      h1 {
        margin-bottom: 24px;
      }

      h4 {
        margin-bottom: 17px;
      }

      ul.detial-price {
        li {
          margin-bottom: 10px;
        }
      }
    }
  }

  // footer css //
  footer {
    .footer-main {
      .footer-logo {
        margin-bottom: 15px;

        img {
          width: 100px;
        }
      }

      h3 {
        margin-bottom: 17px;
      }

      h1 {
        margin-bottom: 15px;
      }

      .footer-btn {
        margin-top: 37px;
      }

      .footer-back-block {
        .inner1 {
          left: -75%;
          top: -160%;
        }

        .inner2 {
          right: -75%;
        }

        .inner3 {
          top: -25%;
        }

        .inner4 {
          bottom: 0;
          left: -10%;
        }

        .inner5 {
          width: 300px;
        }
      }
    }
  }



  .subscribe-content {
    h1 {
      width: auto;
    }

    img {
      width: 400px;
      height: auto;
    }
  }

}

@media(max-width: 1366px) {

  // header
  .landing-page-header {
    .landing-header {
      .navbar {
        .navbar-collapse {
          .navbar-nav {
            .nav-item {
              .nav-link {
                line-height: unset;
              }
            }
          }
        }
      }
    }
  }

  //fisrt section
  .landing_page_slider {
    min-height: 1080px;

    .animated-img-2 {
      margin-top: 0px;
      width: 1000px;
    }

    //animation
    .animated-img-1 {
      .slicing-img-4 {
        left: 230px;
        top: 230px;
      }

      .slicing-img-5 {
        left: 900px;
        top: 466px;
      }

      .slicing-img-6 {
        top: 152px;
        right: 351px;
      }

      .slicing-img-7 {
        top: 270px;
        left: 438px;
      }
    }
  }

  // landing page
  .slider-block {
    .chat-slide {
      width: 70%;
    }
  }

  //main-features
  .main-feature {
    .key-feature {
      .template-collection {
        .image-contain {
          padding: 20px;
          min-height: 160px;
        }
      }
    }
  }
}



@media(max-width: 1199px) {
  .index-main {
    .landing-page-header {
      .home-img img {
        &:nth-child(2) {
          width: 80px;
        }

        &:nth-child(3) {
          width: 100px;
        }
      }
    }

    .trending-features {
      .features-contant {
        h4 {
          width: 100%;
        }
      }
    }
  }

  // genral css //
  .section-pb-space {
    padding-bottom: 40px;
  }

  .section-pt-space {
    padding-top: 40px;
  }

  .section-py-space {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .navbar-expand-sm {
    .navbar-collapse {
      display: unset !important;
    }
  }

  header {
    top: 0px !important;
  }

  .home-wrapper {
    .home-content {
      padding-bottom: 3% !important;
      padding-top: 4% !important;
      justify-content: center;

      .home-info {
        display: flex !important;
        justify-content: space-between;
        gap: 14px;
        margin-bottom: 0 !important;

        li {
          .factors {
            gap: 8px !important;
            padding-bottom: calc(6px + 10*(100vw - 320px)/1600);

            h4 {
              padding-top: 7px;
            }
          }
        }
      }

      .btn-solid {
        margin-top: 0px !important;
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: fit-content;
      }
    }

    .home-img {
      padding-top: 3% !important;
      padding-bottom: 0% !important;

      .home-rounded {
        top: -23% !important;
      }
    }

    h1 {
      width: 100% !important;
      margin-bottom: calc(15px + 0*(100vw - 320px)/1600);
    }

    .home-effect {
      left: 8% !important;
      top: 19%;
    }

    .home-effect1 {
      right: 8% !important;
      top: 34%;
    }

    .home-effect2 {
      left: 84% !important;
      top: 10% !important;
    }
  }

  header {
    .landing-header {
      .landing-header-right {
        .main-nav {
          display: block;
        }
      }
    }
  }

  .testimonial-box {
    padding: 40px;
  }

  // slider  css //
  .slider-block {
    .slider-main {

      .slider-contain {
        .ply-main {
          margin-bottom: 10px;
        }

        h1 {
          margin-bottom: 12px;
        }

        h4 {
          margin-bottom: 12px;
          width: 480px;
        }

        .header-btn {
          .foot-aps-btn {
            padding: 13px 28px;

            i {
              font-size: 24px;
            }
          }

          .foot-ply-btn {
            padding: 13px 28px;

            i {
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  // app design //
  .app-design-main {
    padding-top: 40px;
    padding-bottom: 40px;

    .contant-box {
      h4 {
        width: auto;
      }
    }
  }



  .pages-space {
    >div {
      &:nth-child(n+3) {
        margin-top: 40px !important;
      }
    }
  }




  // chit chat  //
  .chatzy-main {
    .chatzy-contain {
      h1 {
        width: 390px;
      }

      .chatzy-img {
        width: 380px;
      }

      img {
        margin-top: 0;
      }
    }
  }



  // subscribe update css //
  .subscribe-content {
    .form-inline {
      .form-control {
        width: 200px;
      }
    }
  }

  .main-feature {
    .key-feature {
      >div {
        &:nth-child(n+5) {
          margin-top: 30px;
        }
      }
    }
  }
}

@media(max-width: 991px) {
  .index-main {
    .landing-page-header {
      .home-img {
        display: none;
      }

      .home-content {
        padding-top: 0;
        text-align: center;
        padding: 0 calc(0px + (100 - 0) * ((100vw - 320px) / (1920 - 320)));

        h2 {
          width: 100%;
        }

        >div {
          justify-content: center;
        }
      }
    }
  }

  // app design css //
  .app-design-main {
    .icon-box {
      i {
        font-size: 36px;
      }
    }
  }


  // footer css //
  footer {
    .footer-main {
      .footer-logo {
        img {
          width: 80px;
        }
      }

      .footer-back-block {
        .inner4 {
          left: -20%;
        }

        .inner3 {
          top: -30%;
          right: 0;
        }
      }
    }
  }

  .subscribe-content {
    button {
      padding: 12px 18px;
    }
  }
}




@media(max-width: 767px) {
  .custom-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .landing-screen-animation {
    .pc-bg-1 {
      height: 300px !important;
    }
  }

  // ganral css //
  .section-pb-space {
    padding-bottom: 30px;
  }

  .section-pt-space {
    padding-top: 30px;
  }

  .app-code-block {
    >div {
      &:first-child {
        .app-design-main {
          padding-top: 0;
        }
      }
    }
  }

  .landing-page-header {
    header {
      .landing-header {
        .btn_purchase {
          a {
            padding: 12px 25px;
          }
        }
      }
    }
  }


  // chit chat css //
  .chatzy-main {
    .chatzy-contain {
      text-align: center;

      .chatzy-img {
        width: 250px;
        margin: 0 auto
      }

      h3 {
        margin-bottom: 18px;
      }

      h1 {
        width: auto;
        margin-bottom: 18px;
      }

      h4 {
        margin-bottom: 10px;
      }
    }

    .chatzy-back-block {
      .chit-chat3 {
        top: 16%;
        left: 32%;
      }
    }
  }

  .chit-chat-block {
    >div {
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  .chatzy-main {
    .chatzy-contain {
      .chatzy-logo {
        margin-bottom: 18px;

        img {
          width: 150px;
          height: auto;
        }
      }
    }
  }

  // footer css //
  footer {
    .footer-main {
      ul.footer-contact {
        li {
          margin-right: 15px;
        }
      }

      .footer-btn {
        margin-top: 0px;

        .foot-aps-btn {
          padding: 13px 25px;
        }

        .foot-ply-btn {
          padding: 13px 25px;
          margin-left: 20px;
        }
      }
    }
  }

  footer {
    .footer-back-block {
      .inner1 {
        display: none;
      }

      .inner2 {
        display: none;
      }
    }
  }

  .pages-space {
    >div {
      &:nth-child(n+2) {
        margin-top: 30px !important;
      }
    }
  }

  .main-feature {
    .key-feature {
      >div {
        &:nth-child(n+3) {
          margin-top: 30px;
        }
      }
    }
  }




}

@media(max-width: 575px) {
  .home-wrapper {
    padding: calc(20px + 134*(100vw - 320px)/1600) 0 calc(0px + 94*(100vw - 320px)/1600) !important;

    .home-effect1 {
      top: 48%;
    }

    .home-effect2 {
      top: 19% !important;
      width: 6% !important;
    }

    .home-effect {
      width: 11%;
    }

    .home-content {
      .download-btn {
        padding: 8px 30px !important;
        margin-bottom: 20px;
      }
    }
  }

  header {
    .landing-header {
      .navbar-collapse {
        width: 100% !important;
      }
    }
  }

  // header css //
  header {
    padding-top: 20px;

    .landing-header {
      .landing-header-left {
        .logo-block {
          img {
            height: 40px;
            width: auto;
          }
        }
      }

      .landing-header-right {
        .purchase-block {
          .purchase-btn {
            i {
              margin-right: 0;
            }

            span {
              display: none;
            }
          }
        }
      }
    }
  }


  header {
    .landing-header {
      &.fixed {
        padding: 0 !important;
      }

      .logo-block {
        img {
          height: 48px !important;
        }
      }
    }
  }


  header .landing-header // slider css //

  .slider-block {
    .inner2 {
      right: 0;
      top: 10%;
    }
  }

  // slider css //
  .slider-block {
    .inner2 {
      right: 0;
      top: 10%;
    }

    .slider-main {
      padding-top: 80px;
      padding-bottom: 40px;

      .slider-contain {
        width: 380px;
      }
    }

    .downlaod {
      .footer-btn {
        .btn {
          i {
            font-size: 16px;
          }
        }
      }
    }
  }

  .app-design-main {
    padding-top: 20px;
  }

  // chat main cs//

  .chatzy-main {
    .chatzy-contain {
      .chatzy-img {
        width: 200px;
      }

      h3 {
        margin-bottom: 14px;
      }

      h1 {
        margin-bottom: 14px;
      }
    }
  }


  // footer css //
  footer {
    .footer-main {
      .footer-back-block {
        .inner4 {
          left: -25%;
          bottom: -40%;
        }

        .inner3 {
          top: -35%;
          right: -12%;
        }
      }
    }
  }

  .mobile-version-sec {
    .mobile-display {
      text-align: center;
    }

    .mobile-qr {
      max-width: unset;
      margin-left: 0px;

      .qr-img {
        &::after {
          display: none;
        }
      }
    }
  }

}

@media(max-width: 480px) {
  .home-wrapper {
    padding: calc(50px + 134*(100vw - 320px)/1600) 0 calc(0px + 94*(100vw - 320px)/1600) !important;

    .home-img {
      .home-rounded {
        top: -35% !important;
      }
    }
  }
}

@media(max-width: 420px) {
  // footer css //
  footer {
    .footer-main {
      .footer-btn {
        display: block;

        .foot-ply-btn {
          width: fit-content;
          margin-top: 20px;
        }

        .foot-aps-btn,
        .foot-ply-btn {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

@media(max-width: 360px) {
  .index-main {
    .landing-page-header {
      .home-content {
        >div {
          display: block;

          .downlaod {
            margin-top: 12px;
          }
        }
      }
    }
  }

  .home-wrapper {
    .home-img {
      .home-rounded {
        top: -46% !important;
        left: 0px !important;
      }
    }
  }

  .feture-icon {
    padding: 15px;
  }
}