/**** Index page Responsive****/
@media (max-height: 1680px) {
  .chatzy-main {
    .messages {
      .contact-details {
        ul {
          li {
            padding: 0 10px;
          }
        }
      }
    }
  }
}

@media (max-height: 730px) {

  .chatzy-container {
    .sidebar-bottom {
      padding-bottom: 25px;
    }
  }
}

@media (max-width: 1500px) {
  .recent {
    .template-title {

      .mobile-back,
      .mainnav {
        height: 32px;
        width: 32px;
      }
    }
  }

  .chatzy-main .messages .contact-details .left.d-flex .media-right .mute i:after {
    top: 14px;
    left: 5px;
  }

  .contact-chat {
    padding: 30px 30px 120px;

    .profile {
      height: 50px;
      width: 50px;
    }
  }

  .contact-content {
    overflow: scroll;
    height: 100%;

    .col-sm-5,
    .col-sm-7 {
      flex: 100%;
      max-width: 100%;
    }

    .col-sm-7 {
      margin-top: 20px;
    }
  }

  .contact-details {
    >.row {
      .icon-btn {
        height: 34px;
        width: 34px;
      }
    }
  }
}

@media (max-width: 1366px) {

  .chatzy-main {
    .messages {
      .contact-details {
        width: calc(100vw - 300px);
      }
    }
  }

  .sidebar-toggle {
    .main-nav {
      padding: 20px 0 25px;
      display: grid;

      .logo-warpper {
        padding-bottom: 14px;
      }
    }
  }

  .header-icon {
    margin-top: 14px;
  }

  .chat-main {
    li {
      .chat-box {
        .details {
          h5 {
            max-width: 122px;
          }
        }
      }
    }
  }

  .template-tab {
    &.tab-sm {
      >.nav-tabs {
        >.nav-item {
          .nav-link {
            padding: 9px 12px;
          }
        }
      }
    }
  }

  .template-tab {
    .nav-tabs {
      .nav-item {
        .nav-link {
          padding: 7px 35px;
        }
      }
    }
  }

  .profile-box {
    .details.edit {
      width: 170px;
    }
  }

  .chatzy-main {
    .message-input {
      input {
        width: calc(100% - 75px);
      }
    }
  }

  .groupuser {
    .gr-chat-friend-toggle {
      .gr-chat-frind-content {
        padding: 10px 20px;

        .chat-main li {
          padding-bottom: 0;
        }
      }
    }
  }

  header {
    .landing-header {
      &.fixed {
        .navbar-collapse {
          .navbar-nav {
            margin-left: auto;
          }
        }
      }

      .navbar-collapse {
        .navbar-nav {
          border-radius: 15px;
          padding-left: 0;
          margin-left: auto;
        }
      }
    }
  }

  .chat-main li {
    .chat-box {
      .details {
        h6 {
          max-width: 150px;
        }
      }
    }
  }

  .search2>div {
    height: 40px;
  }

  .group-main li {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .close-app {
    display: none;
  }

  .contact-chat {
    >ul {
      >li {

        &.sent,
        &.replies {
          width: 100%;
        }
      }
    }
  }

  .emojis-main {
    .contact-poll {
      .mr-4 {
        margin-right: 0.5rem !important;
      }
    }
  }

  .chatzy-main {
    .message-input {
      padding: 12px 25px;

      &:after {
        bottom: -66px;
      }
    }

    li {
      .chat-box {
        .details {
          h6 {
            max-width: 130px;
          }
        }
      }

      &.active {
        .details {
          h6 {
            letter-spacing: unset;
          }
        }
      }
    }
  }

  .setting-block {
    padding: 30px 40px;

    .block {
      padding: 20px 20px 0 20px;
      top: 100px;
    }
  }

  .chatzy-container {
    .chatzy-left-sidebar {

      .contact-list-tab,
      .settings-tab,
      .recent-default,
      .status-tab {
        padding: 40px 20px;
      }
    }

    .chatzy-left-sidebar {
      min-width: 300px;
      width: 300px;
      //animation: fadeInright 300ms ease-in-out;
      //transition: all 0.3s ease;

      .contact-list-tab,
      .settings-tab,
      .recent-default,
      .status-tab {
        width: 300px;
        min-width: 300px;
      }

      .status-tab {
        .my-status-box {
          padding-bottom: 20px;
        }
      }

      .recent-default {
        .chat {
          >.template-tab {
            padding: 20px 20px 36px;

            &.chat-tabs {
              .nav-tabs {
                padding-bottom: 20px;
                flex-wrap: nowrap;
              }

              >ul {
                >li {
                  >a {
                    svg {
                      height: 10px;
                      width: 10px;
                      margin-right: 5px;
                    }
                  }
                }
              }
            }

            .nav-tabs {
              .nav-item {
                .nav-link {
                  min-width: 60px;
                }
              }
            }
          }

          .template-title {
            padding: 40px 20px 0 20px;
          }

          .search-form {
            width: calc(100% - 30px);
            left: 15px;
          }
        }

        .recent {
          width: 300px;

          ~.chat {
            margin-top: 280px;
          }

          .recent-slider {
            padding: 10px 20px 0 20px;

            .recent-box {
              &::before {
                left: 5px;
              }
            }

            .owl-stage-outer {
              height: 110px;
            }
          }

          .template-title {
            padding: 0 20px;

            .img-text {
              .chat-box {
                .profile {
                  height: 60px;
                  width: 60px;
                  margin-right: 10px;
                }

                .details {
                  h2 {
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .contact-log-main {
    li {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  .m-r-15 {
    margin-right: 5px;
  }

  .sidebar-toggle {
    .main-nav:not(.on) {
      &~.chatzy-main {
        &.small-sidebar {
          width: calc(100% - 390px);
        }
      }
    }
  }

  .app-list-ul {
    li {
      &.right-sidebar-profile {
        .profile {
          margin: 0 auto;
        }
      }
    }
  }

  .app-sidebar {
    .app-list {
      ul {
        &.app-list-ul {
          padding-top: 20px;
        }
      }
    }
  }

  .sidebar-active {
    .chatzy-container {
      .chatzy-main.small-sidebar {
        .messages {
          .contact-details {
            width: calc(100vw - 400px);
            padding: 25px 30px;
          }
        }
      }
    }

    .sidebar-toggle {
      .main-nav.on {
        &~.chatzy-main {
          &.small-sidebar {
            .contact-details {
              width: calc(100vw - 500px);
            }
          }
        }
      }
    }

    .chatzy-container {
      .chatzy-main {
        &.small-sidebar {
          width: calc(100% - 490px);
        }
      }
    }
  }

  .template-tab {
    &.tab-icon {
      .nav-tabs {
        .nav-item {
          .nav-link {
            padding: 7px 15px;
          }
        }
      }
    }
  }

  .chat-main,
  .contact-log-main {
    li {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;

      .chat-box,
      .contact-box {
        display: flex;
        align-items: center;

        .details {
          padding-left: 60px;
          padding-right: 50px;
        }
      }
    }
  }

  .chat {
    .profile {
      width: 50px;
      height: 50px;
    }
  }

  .contact-log-main {
    li {
      .contact-box {
        .details {
          padding-right: 80px;
          padding-left: 60px;
        }
      }
    }
  }

  .group-main {
    .group-box {
      .details {
        padding-left: 60px;
      }
    }
  }

  .sidebar-toggle {
    .main-nav {
      &.on {
        ~.chatzy-main {
          .contact-details {
            width: calc(100vw - 418px);
            padding: 25px 30px;
          }

          .message-input {
            width: calc(100% - 458px);
          }
        }
      }
    }
  }

  .sidebar-active {
    .sidebar-toggle {
      .main-nav {
        &.on {
          ~.chatzy-main {
            .message-input {
              width: calc(100% - 790px);
            }

            .contact-details {
              width: calc(100vw - 880px);
            }

            &.small-sidebar {
              .message-input {
                width: calc(100% - 539px);
              }
            }
          }
        }
      }
    }

    .chatzy-container {
      .chatzy-main {
        width: calc(100% - 720px);

        &.small-sidebar {
          .message-input {
            width: calc(100% - 440px);
          }
        }
      }
    }
  }

  .chatzy-main {
    .message-input {
      width: calc(100% - 344px);
    }
  }

  //login page
  .login-content {
    .line {
      h6 {
        &::before {
          width: 4rem;
        }

        &::after {
          width: 4rem;
        }
      }
    }
  }

  //header
  header {
    .landing-header {
      .navbar-collapse {
        width: 100%;
      }
    }
  }
}

@media (min-width: 1199.98px) {
  .main-menu {
    .collapse {
      display: block;
    }
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-sm {
    .navbar-nav {
      .dropdown-menu {
        position: absolute !important;
      }
    }
  }

  .index-main {
    .client-images {
      img {
        width: 2000px;
      }
    }
  }

  header {
    .landing-header {
      .navbar-collapse {
        .navbar-nav {
          background-color: transparent;
          margin-left: 0;
        }
      }
    }
  }

  .landing-page {
    .landing-header {
      .navbar-light {
        .navbar-nav {
          .active {
            >.nav-link {
              color: rgba(0, 0, 0, 0.9);
            }
          }

          .nav-link {
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }

  .mobile-version-sec .chatzy-back-block .chit-chat2 {
    display: none;
  }

  .collaboration-main .collaboration-content ul.collab-tab.nav.nav-pills li.nav-item a.nav-link {
    .tab-content {
      h3 {
        margin-bottom: 3px !important;
      }
    }

    .tab-main .tab-img i {
      font-size: 24px;
    }
  }

  .chat-landing-page {
    .content-title {
      margin-bottom: 35px;
    }
  }

  .emojis-main {
    .contact-poll {
      .contact-poll-content:before {
        left: 30px;
      }
    }
  }

  header {
    padding-top: 0;
    padding-bottom: 0 !important;
    clear: both;

    .landing-header {
      .dropdown-menu.show {
        position: relative;
        width: calc(100% - 30px);
        margin-bottom: 20px;
        padding-bottom: 10px;
        margin-top: 10px;
      }

      .logo-block {
        img {
          height: 40px;
        }
      }

      .navbar-toggler {
        position: absolute;
        right: 20px;
        top: 15px;
        background-color: $white;
        display: unset;
      }

      .navbar-collapse {
        position: absolute;
        top: 64px;
        background-color: $white;
        right: 0;
        width: 36%;
        border-radius: $radious15;
        padding: 20px 0;
        border: 1px solid $gray1;

        &:not(.show) {
          display: none !important;
        }

        .navbar-nav {
          display: block;

          .nav-item {
            display: block;

            .nav-link {
              padding-top: 10px;
              padding-bottom: 10px;
              color: rgba($black, 0.8) !important;
            }
          }
        }
      }
    }
  }

  .chatzy-main {
    .messages {
      .contact-details {
        padding: 15px;

        .media-right {
          ul {
            display: flex;
          }
        }
      }
    }

    .message-input {
      width: calc(100% - 342px);
    }

    .messages {
      .contact-details {
        .col {
          width: 100px;
        }

        .left.d-flex {
          .flex-grow-1 {
            display: none;
          }
        }
      }
    }
  }

  .template-landing {
    header {
      .landing-header {
        .navbar-collapse {
          padding: 20px;

          .navbar-nav {
            .nav-item {
              margin-bottom: 20px;
              padding-left: 0;
              margin-left: 0;
              line-height: 1;

              &:last-child {
                margin-bottom: 0;
              }

              >.nav-link {
                color: rgba(0, 0, 0, 0.7) !important;
                transition: all 0.5s ease;
                padding: 0;

                &:hover {
                  color: $primary-color !important;
                }
              }
            }
          }
        }

        .dropdown-menu {
          .nav-link {
            a {
              transition: all 0.5s ease;

              &:hover {
                color: $primary-color !important;
              }
            }
          }
        }
      }
    }
  }

  .rate-us {
    padding-top: 40px !important;
  }


}

@media (max-width: 1024px) {
  .chatzy-main {
    .messages {
      .contact-details {
        .left.d-flex {
          .flex-grow-1 {
            &:before {
              top: -4px;
            }
          }
        }

        .flex-grow-1 {
          h5 {
            display: none;
          }
        }
      }
    }
  }

  .sidebar-toggle {
    .main-nav {
      &.on {
        ~.chatzy-main {
          .contact-details {
            ul {
              li {
                padding-left: 4px !important;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-active {
    .sidebar-toggle {
      .main-nav {
        &.on {
          ~.chatzy-main {
            &.small-sidebar {
              .contact-details {
                ul {
                  li {
                    padding-left: 21px;
                  }
                }
              }
            }
          }
        }

        ~.chatzy-main {
          .messages {
            .contact-details {
              ul {
                li {
                  padding-left: 8px;
                }
              }
            }
          }
        }
      }
    }

    .chatzy-container {
      .chatzy-main {
        &.small-sidebar {
          width: calc(100% - 490px);
        }
      }
    }
  }

  .chatzy-main {
    .messages {
      .contact-details {
        padding: 15px !important;

        .media-right {
          ul {
            display: flex;
          }
        }
      }
    }

    .message-input {
      padding: 12px 20px;
    }
  }

  // faq section
  .faq-section {
    .accordion {
      &.template-accordion {
        .card {
          .card-header {
            button {
              &:before {
                right: 10px;
              }
            }
          }
        }
      }
    }
  }

}

@media (max-width: 992px) {
  .chatzy-main {
    .message-input:after {
      bottom: -62px;
    }
  }

  .sidebar-active {
    .chatzy-container {
      .chatzy-main {
        &.small-sidebar {
          .messages {
            .contact-details {
              padding: 15px 30px;
              width: calc(100vw - 418px);
            }
          }
        }
      }
    }
  }

  .sidebar-active {
    .sidebar-toggle {
      .main-nav {
        &.on~.chatzy-main {
          &.small-sidebar {
            .contact-details {
              width: calc(100vw - 418px);
            }
          }
        }
      }
    }
  }

  .sidebar-toggle .main-nav.on~.chatzy-main .contact-details {
    width: calc(100vw - 400px);
  }

  .img-text {
    .chat-box {
      padding: 10px 0;

      .details {
        h4 {
          padding-top: 4px;
        }
      }
    }
  }

  .chatzy-main .message-input input {
    width: calc(100% - 90px);
  }

  .chatzy-container {
     &.sidebar-overlap {
      .chatzy-main {
        .messages {
          .contact-details {
            width: calc(100%) !important;
          }
        }
      }
    }

    &.sidebar-overlap {

      .chatzy-main,
      .message-input,
      .contact-details {
        width: calc(100%) !important;
      }
    }
  }

  .chat-landing-page {
    .landing_page_slider {
      min-height: 800px;
      background-position: bottom;
    }
  }

  .chatzy-main {
    .messages {
      height: calc(100vh - 0px);
      min-height: calc(100vh - 0px);
    }

    .message-input {
      padding: 10px 20px;
    }
  }

  [class*="introjs"] {
    display: none;
  }

  .main-nav.introjs-fixParent,
  .sidebar-top.introjs-relativePosition {
    display: block;
  }

  .chatzy-main .messages .contact-details {
    width: calc(100vw - 302px);
  }

  header {
    .landing-header.fixed {
      padding: 5px 15px;
    }
  }

  .sidebar-active {
    .sidebar-toggle {
      .main-nav {
        &.on {
          ~.chatzy-main {
            &.small-sidebar {
              .message-input {
                width: calc(100% - 215px);
              }
            }
          }

          &~.show-recent {
            &~.chatzy-main {
              &.small-sidebar {
                .messages {
                  .contact-details {
                    width: 33.3vw !important;

                    ul {
                      li {
                        padding-left: 9px;
                      }
                    }
                  }
                }

                .message-input {
                  width: calc(100% - 590px);
                }
              }
            }
          }
        }

        &~.show-recent {
          &~.chatzy-main {
            &.small-sidebar {
              .messages {
                .contact-details {
                  width: 44vw;

                  ul {
                    li {
                      padding-left: 24px;
                    }
                  }
                }
              }

              .message-input {
                width: calc(100% - 500px);
              }
            }
          }
        }
      }
    }

    .chatzy-container {
      .chatzy-main {
        &.small-sidebar {
          width: calc(100% - 92px);

          .contact-details {
            width: 84vw;
          }
        }
      }
    }
  }

  .sidebar-toggle {
    .main-nav {
      &.on {
        &~.show-recent {
          &~.chatzy-main {
            .messages {
              .contact-details {
                width: 42.5vw !important;

                ul {
                  li {
                    padding-left: 21px;
                  }
                }
              }
            }

            .message-input {
              width: calc(100% - 500px);
            }
          }
        }
      }

      &~.show-recent {
        &~.chatzy-main {
          .messages {
            .contact-details {
              width: 53vw;

              ul {
                li {
                  padding-left: 9px;
                }
              }
            }
          }

          .message-input {
            width: calc(100% - 400px);
          }
        }
      }
    }
  }

  .recent {
    .template-title {
      .close-contentbtn {
        display: flex;
      }
    }
  }

  //login page
  .login-content {
    .line {
      h6 {
        &::before {
          width: 3.5rem;
        }

        &::after {
          width: 3.5rem;
        }
      }
    }
  }

  .mr-3 {
    margin-right: 10px !important;
  }

  footer {
    .footer_order_2 {
      order: -1;
    }

    .footer_order_1 {
      order: -2;
    }

    .footer_order_3 {
      margin-top: 40px;
    }
  }
}

@media(max-width:991px) {

  // footer css //
  footer {
    .footer-block {
      display: flex;
      justify-content: center;

      >div {
        margin-top: 30px;

        &:last-child,
        &:first-child {
          order: -1;
          margin-top: 0px;
        }
      }
    }
  }

  .real-time-customizer,
  .collaboration-main,
  .dark-light,
  .easy-navigation {
    .chit-chat5 {
      display: none;
    }
  }
}

@media (max-width: 800px) {
  .chatzy-container {
    .chatzy-left-sidebar {

      .contact-list-tab,
      .settings-tab,
      .recent-default,
      .status-tab {
        padding: 20px 20px 40px;
      }
    }
  }

  .chatzy-main {
    .messages {
      .contact-details {
        .left.d-flex {
          .media-right {
            display: block;
          }
        }
      }
    }
  }

  .chatzy-container {
    .chatzy-left-sidebar {
      .recent-default {
        .recent {
          ~.chat {
            margin-top: 257px;
          }
        }

        .chat {
          .template-title {
            padding: 20px 20px 0;
          }

          >.template-tab {
            &.chat-tabs {
              >ul {
                >li {
                  >a {
                    padding: 12px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .sidebar-toggle .main-nav.on~.chatzy-main .contact-details {
    width: calc(100vw - 403px);
  }

  .chatzy-left-sidebar .search-form .form-group .icon-close {
    top: 3px !important;
  }

  .recent .template-title .mainnav {
    margin: 0 8px;
  }

  .profile-box .details.edit {
    width: 90%;
  }

  .chatzy-container {
    overflow: hidden;
  }

  .mobile-sidebar,
  .mobile-back {
    display: inline-flex;
  }

  .search2>div {
    width: 100%;
    height: 50px;
  }

  .template-tab.tab-icon {
    .nav-tabs {
      .nav-item {
        .nav-link {
          padding: 10px 35px;
          font-size: 16px;

          svg {
            height: 22px;
          }
        }
      }
    }
  }

  .chat {
    .details {
      padding-left: 75px !important;
    }
  }

  .chat,
  .chatzy-left-sidebar {
    .details {
      h6 {
        max-width: unset;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (800 - 320)));
        padding-top: 6px;
      }
    }

    h5 {
      font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (800 - 320)));
    }

    .profile {
      width: 60px;
      height: 60px;
    }
  }

  .template-tab {
    >.nav-tabs {
      >.nav-item {
        .nav-link {
          padding: 12px 60px;
          font-size: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }

  .chatzy-container {
    .main-nav {
      &.on {
        ~.chatzy-left-sidebar {
          .dynemic-sidebar {
            width: calc(100vw - 100px);
            min-width: calc(100vw - 100px);

            &.recent-default {

              .recent,
              .chat {
                width: calc(100vw - 100px);
              }
            }
          }
        }
      }
    }

    .chatzy-left-sidebar {
      .recent-default {
        .chat {
          .search {
            margin-top: 10px;
          }

          h2 {
            font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (800 - 320)));
          }

          >.template-tab.chat-tabs {
            >ul {
              >li {
                >a {
                  font-size: calc(13px + (18 - 14) * ((100vw - 320px) / (800 - 320)));
                  padding: 5px 40px;

                  svg {
                    height: 14px;
                    width: 14px;
                  }
                }
              }
            }
          }
        }

        .recent {
          width: 100vw;
        }
      }
    }
  }

  .chatzy-container {
    .chatzy-left-sidebar {
      width: calc(100vw - 1px);
      min-width: calc(100vw - 1px);
      //animation: fadeInRight 300ms ease-in-out;
      //transition: all 0.3s ease;

      .contact-list-tab,
      .settings-tab,
      .recent-default,
      .status-tab {

        width: calc(100vw - 1px);
        min-width: calc(100vw - 1px);
        //animation: fadeInRight 300ms ease-in-out;
        //transition: all 0.3s ease;
      }
    }
  }

  .sidebar-toggle {
    .main-nav:not(.on) {
      &~.chatzy-main {
        &.small-sidebar {
          width: calc(100% - 90px);

          .message-input {
            width: calc(100% - 140px);
          }

          .messages {
            .contact-details {
              width: calc(100vw - 100px);
            }
          }
        }
      }
    }
  }

  .chatzy-container {
    .chatzy-main {
      .message-input {
        width: calc(100% - 44px);
//        animation: fadeInRight 300ms ease-in-out;
//        transition: all 0.3s ease;
      }

      .messages {
        .contact-details {
          width: calc(100vw - 3px);
//          animation: fadeInRight 300ms ease-in-out;
//          transition: all 0.3s ease;
        }
      }
    }

    .chatzy-main {
      width: 100vw;
      max-width: 100vw;
      overflow: hidden;
    }

    &.mobile-menu {
      .chatzy-left-sidebar {
        display: none;
        width: 0;
        min-width: 0;
        opacity: 0;
//        animation: fadeInRight 300ms ease-in-out;
//        transition: all 0.3s ease;
      }
    }
  }

  .chat-tabs {
    ul {
      li.nav-item {
        width: 100%;
        margin: 0 10px;

        a {
          width: 100%;
        }
      }
    }
  }

  ul {
    &.chat-cont-setting {
      left: unset;
      right: 20px;
    }
  }
}

@media (max-width: 767.98px) {
  .index-main {
    .client-images {
      img {
        width: 1500px;
      }
    }
  }

  .faq-pages {
    img {
      display: none;
    }
  }

  .modal-dialog-centered .modal-content {
    max-height: 90vh;
    overflow: auto;
  }

  .zoomcontent {
    display: none;
  }

  .chat-landing-page {
    h1 {
      margin-bottom: 10px;
    }
  }

  .chatzy-loader {
    h3 {
      margin-top: 15px;
      padding: 0 15px;
      line-height: 1.4;
    }

    img {
      height: 80px;
    }
  }

  .chatzy-main {
    .messages {
      .contact-details {
        width: calc(100vw - 390px);

        .left.media {
          .media-right {
            .icon-btn {
              i {
                font-size: 21px;
              }

              svg {
                vertical-align: unset;
                stroke-width: 2px;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-active {
    .sidebar-toggle {
      .main-nav {
        &.on {
          ~.chatzy-main {
            &.small-sidebar {
              .messages {
                .contact-details {
                  width: 66.3vw !important;

                  ul {
                    li {
                      padding-left: 33px;
                    }
                  }
                }
              }

              .message-input {
                width: calc(100% - 200px);
              }
            }

            .messages {
              .contact-details {
                ul {
                  li {
                    padding-left: 24px;
                  }
                }
              }
            }
          }
        }

        ~.chatzy-main {
          .messages {
            .contact-details {
              ul {
                li {
                  padding-left: 28px;
                }
              }
            }
          }

          &.small-sidebar {
            .messages {
              .contact-details {
                ul {
                  li {
                    padding-left: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .contact-details {
    .media-right {
      ul {
        display: flex;
      }
    }
  }

  .sidebar-toggle {
    .main-nav {
      &.on {
        ~.chatzy-main {
          .contact-details {
            width: 78.5vw !important;
          }

          .message-input {
            width: calc(100% - 100px);
          }
        }
      }

      &~.show-recent {
        &~.chatzy-main {
          .messages {
            .contact-details {
              width: 39vw;

              ul {
                li {
                  padding-left: 17px;
                }
              }
            }
          }
        }
      }
    }
  }

  .copyright {
    p {
      text-align: center;
    }
  }

  .links_horizontal {
    p {
      text-align: center !important;
    }
  }

  .chatzy-main {
    .messages {
      .contact-details {
        width: calc(100vw - 390px);

        .left.d-flex {
          .media-right {
            .icon-btn {
              i {
                font-size: 21px;
              }

              svg {
                vertical-align: unset;
                stroke-width: 2px;
              }
            }
          }
        }
      }
    }
  }

  .sidebar-active {
    .sidebar-toggle {
      .main-nav {
        &.on {
          ~.chatzy-main {
            &.small-sidebar {
              .messages {
                .contact-details {
                  width: 66.3vw !important;

                  ul {
                    li {
                      padding-left: 33px;
                    }
                  }
                }
              }

              .message-input {
                width: calc(100% - 200px);
              }
            }

            .messages {
              .contact-details {
                ul {
                  li {
                    padding-left: 24px;
                  }
                }
              }
            }
          }
        }

        ~.chatzy-main {
          .messages {
            .contact-details {
              ul {
                li {
                  padding-left: 28px;
                }
              }
            }
          }

          &.small-sidebar {
            .messages {
              .contact-details {
                ul {
                  li {
                    padding-left: 2px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .contact-details {
    .media-right {
      ul {
        display: flex;
      }
    }
  }

  .sidebar-toggle {
    .main-nav {
      &.on {
        ~.chatzy-main {
          .contact-details {
            width: 78.5vw !important;
          }

          .message-input {
            width: calc(100% - 100px);
          }
        }
      }

      &~.show-recent {
        &~.chatzy-main {
          .messages {
            .contact-details {
              width: 39vw;

              ul {
                li {
                  padding-left: 17px;
                }
              }
            }
          }
        }
      }
    }
  }

  .copyright {
    p {
      text-align: center;
    }
  }

  .links_horizontal {
    .text-right {
      text-align: center !important;
    }
  }

  // footer
  footer {
    .footer-title {
      .according-menu {
        font: normal normal normal 14px/1 FontAwesome;

        &:before {
          content: "\f107";
          position: absolute;
          right: 20px;
          top: 0px;
          color: $primary-color;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    .footer-title {
      &.active {
        .according-menu {
          font: normal normal normal 14px/1 FontAwesome;

          &:before {
            content: "\f106";
            position: absolute;
            right: 20px;
            top: 2px;
            color: $primary-color;
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
    }
  }


  footer {
    .links {
      li {
        +li {
          margin-top: 5px;
        }
      }
    }

    .logo {
      img {
        width: 105px;
        height: auto;
      }
    }

    .footer-mobile-title {
      display: block;
    }

    .footer-block {
      >div {
        &:nth-child(n+2) {
          margin-top: 15px !important;

          .footer-title {
            border-top: 1px solid rgba($primary-color, 0.2);
          }
        }
      }
    }

    h3 {
      margin-top: 15px !important;
      margin-bottom: 0 !important;
    }

    .footer-contant {
      margin-top: 20px;

      .footer-btn {
        display: grid;
        width: fit-content;

        .btn {
          display: inline-flex;
          padding: 10px 20px;

          i {
            font-size: 16px;
          }
        }
      }

      ul {
        li {
          a {
            font-size: 13px;

            &::before {
              font-size: 8px;
            }
          }
        }
      }
    }
  }

  .footer_order_1 {
    width: 60%;
    float: left;
  }

  .footer_order_2 {
    width: 40%;
    float: right;
  }

  .footer_order_3 {
    width: 33%;
    float: left;
  }

  // element page
  .img-10 {
    width: 5px !important;
  }

  .img-20 {
    width: 10px !important;
  }

  .img-30 {
    width: 20px !important;
  }

  .img-40 {
    width: 25px !important;
  }

  .img-50 {
    width: 35px !important;
  }

  .img-60 {
    width: 45px !important;
  }

  .img-70 {
    width: 55px !important;
  }

  .img-80 {
    width: 65px !important;
  }

  .img-90 {
    width: 75px !important;
  }

  .img-100 {
    width: 85px !important;
  }



  // modal popup css //
  .add-popup {
    .modal-footer {
      padding: 20px 20px;
    }
  }



  footer {
    .footer-mobile-title {
      h3 {
        margin-top: 0 !important;
      }
    }
  }

  .add-popup {

    .modal-header,
    .modal-footer {
      padding: 20px 25px;
    }
  }

  //reminder modal //


  .reminder-modal-main {
    .modal-content {
      .modal-body {
        .reminder-btn {
          .btn {
            padding: 12px 25px;
          }
        }

        .form-group {
          ul {
            &.reminder-count {
              li {
                padding: 10px 15px;
              }
            }
          }
        }
      }
    }
  }



}

@media (max-width: 1640px) and (min-width: 1500px) {
  .chatzy-main {
    .messages {
      .contact-details {
        ul {
          li {
            padding: 0 8px;

            &:first-child {
              padding-left: 0px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1590px) and (min-width: 1500px) {
  .contact-details {
    .profile {
      margin-right: 8px;
      width: 52px;
      height: 52px;
    }

    .icon-btn {
      height: 42px !important;
      width: 42px !important;
    }

    ul {
      li {
        padding-left: 4px !important;
      }
    }

    .flex-grow-1 {
      h5 {
        margin-bottom: 0px !important;
      }
    }
  }
}

@media (max-width: 840px) {
  .contact-details {
    .profile {
      margin-right: 0px !important;
    }
  }
}

@media (max-width: 930px) {
  .contact-details {
    .profile {
      margin-right: 0px;
    }
  }
}

@media (max-width: 600px) {
  .icon-btn.btn-outline-primary:focus {
    box-shadow: none;
  }

  .app-sidebar {
    &.active {
      .apps-ul {
        .template-title {
          margin-top: 0px;
        }
      }
    }
  }

  .message-input {
    .emojis-main {
      .btn-outline-primary {
        background-color: transparent !important;
      }

      .dot-btn {
        margin-right: 6px !important;

        &::before {
          display: none;
        }
      }
    }
  }

  .chat-content {
    .message-input {
      .emojis-main {
        .contact-poll {
          margin-right: 0px;
        }
      }
    }
  }

  .chatzy-container {
    .chatzy-main {

      .contact-content {
        padding: 20px;
      }

      .messages {
        .contact-details {
          .col-7 {
            padding-right: 0;

            .media-right {
              order: -1;

              li {
                padding-left: 10 !important;
                padding-right: 8px !important;
              }
            }
          }
        }
      }
    }

    .chatzy-left-sidebar {
      .recent-default {
        .chat {
          >.template-tab.chat-tabs {
            >ul {
              >li {
                >a {
                  padding: 5px 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .emojis-main {
    .contact-poll {
      .contact-poll-content {
        bottom: 60px;
        left: -23px;
      }
    }
  }

  .contact-chat {
    margin-top: 70px;
    padding-left: 15px;
    padding-right: 15px;
  }

  footer {
    .footer-title {
      .according-menu {
        font: normal normal normal 14px/1 FontAwesome;
      }
    }

    .footer-title {
      &.active {
        .according-menu {
          font: normal normal normal 14px/1 FontAwesome;
        }
      }
    }

    .footer_order_1,
    .footer_order_2,
    .footer_order_3 {
      width: 100%;
    }
  }

  .sidebar-active {
    .sidebar-toggle {
      .main-nav {
        &.on {
          ~.chatzy-main {
            &.small-sidebar {
              .contact-details {
                width: 57.3vw !important;

                ul {
                  li {
                    padding-left: 30px;
                  }
                }
              }
            }
          }
        }

        ~.chatzy-main {
          .messages {
            .contact-details {
              width: calc(100% - 0px) !important;

              ul {
                li {
                  padding-left: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  .chatzy-main {
    .message-input {
      &:after {
        bottom: -60px;
      }
    }
  }

  .chatzy-main {
    .messages {
      .contact-details {
        border-bottom: none;
        padding: 23px 30px 23px 15px !important;
      }
    }
  }

  .sidebar-toggle {
    .main-nav:not(.on) {
      ~.chatzy-main {
        &.small-sidebar {
          width: calc(100% - 0px) !important;

          .message-input {
            width: calc(100% - 44px);
          }
        }
      }
    }
  }

  .profile {
    &.unreachable {
      &:before {
        bottom: 0;
      }
    }
  }

  .chatzy-main {
    .message-input {
      height: 60px;
      padding: 14px 10px;

      .icon-btn {
        height: 32px;
        width: 32px;

        i {
          font-size: 13px;
        }
      }
    }

    .messages {
      height: calc(100vh - 0px);
      min-height: calc(100vh - 0px);

      .contact-details {
        .left {
          &.d-flex {
            .media-left {
              height: unset;
            }
          }
        }

        .profile {
          width: 40px !important;
          height: 40px !important;
        }
      }
    }
  }

  .sidebar-toggle {
    .main-nav {
      &.on {
        &~.chatzy-main {
          .messages {
            .contact-details {
              width: 93.5vw !important;

              ul {
                li {
                  padding-left: 0px;
                }
              }
            }
          }
        }
      }
    }

    //footer
    footer {

      padding-bottom: 0px;

      .footer-mobile-title {
        display: block !important;
      }

      .footer-title {
        .according-menu {
          font: normal normal normal 14px/1 FontAwesome;
        }
      }

      .footer-title {
        &.active {
          .according-menu {
            font: normal normal normal 14px/1 FontAwesome;
          }
        }
      }

      .footer_order_3 {
        margin-top: 0px;
      }

      .downlaod {
        margin-top: 0px;

        .footer-btn {
          .btn {
            margin-left: 15px;
            margin-top: unset;
          }
        }
      }

      .links {
        margin-top: 0px;
      }

      .section-py-space {
        padding-bottom: 30px;
      }

      .footer-contant {
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .landing_page_slider {
    .animated-img-2 {
      display: none;
    }
  }

  .sidebar-toggle .main-nav.on {
    position: absolute;
    left: 0;
    z-index: 9999 !important;
    display: grid;
  }

  .chatzy-container .main-nav.on~.chatzy-left-sidebar .recent-default {
    width: 100vw;
    min-width: 100vw;
  }

  .chatzy-container .main-nav.on~.chatzy-left-sidebar .dynemic-sidebar:not(.recent-default) {
    margin-left: 100px;
  }

  .chatzy-container .main-nav.on~.chatzy-left-sidebar .dynemic-sidebar.recent-default .recent,
  .chatzy-container .main-nav.on~.chatzy-left-sidebar .dynemic-sidebar.recent-default .chat {
    width: 100vw;
  }

  .landing_page_slider {
    .animated-img-2 {
      display: none;
    }
  }

  .chat-landing-page {
    .landing_page_slider {
      min-height: 450px;
    }
  }

  .chat-landing-page {
    .landing_page_slider {
      .landing_page_slider-main {
        .landing_page_slider-contain {

          h1 {
            color: $dark-color !important;

            -webkit-text-fill-color: unset;
          }

          h4 {
            color: $dark-color !important;

            span {
              color: $dark-color !important;
            }
          }
        }

      }
    }
  }

  .sidebar-toggle {
    .main-nav {
      &.on {
        ~.chatzy-main {
          .contact-details {
            width: 71.5vw !important;

            ul {
              li {
                padding-left: 9px;
              }
            }
          }
        }
      }
    }
  }

  .inner-page {
    header {
      padding-top: 0px;
      padding-bottom: 15px;

      .landing-header {
        .navbar-collapse {
          width: 100% !important;
        }
      }
    }

    header {
      .landing-header {
        .navbar-toggler {
          top: 12px;
        }
      }
    }
  }

  .template-landing {
    header {
      .landing-header {
        .navbar-collapse {
          width: 100% !important;

          .navbar-nav {
            .nav-item {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }

  .sidebar-active {
    .sidebar-toggle {
      .main-nav {
        &.on {
          ~.chatzy-main {
            &.small-sidebar {
              .contact-details {
                width: 55.3vw !important;

                ul {
                  li {
                    padding-left: 24px;
                  }
                }
              }
            }
          }
        }

        .chatzy-main {
          .messages {
            .contact-details {
              ul {
                li {
                  padding-left: 11px;
                }
              }
            }
          }
        }
      }
    }
  }

  // modal popup css //
  .add-popup {
    .modal-body {
      padding: 15px;
    }

    .modal-footer {
      .btn {
        padding: 10px 20px;
      }
    }
  }

  .msg-chat-modal,
  .msg-chat-modal {
    .chat-main {
      li {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .chat-landing-page {
    .landing_popups {
      .btn {
        padding: 10px 18px;
      }
    }
  }

  .msg-chat-modal {
    .chat-main {
      li {
        .chat-box {
          .profile {
            width: 40px;
            height: 40px;
          }

          .details {
            padding-left: 50px;
          }
        }
      }
    }




  }

  .add-popup {

    .modal-header,
    .modal-footer {
      padding: 15px 25px;
    }
  }

  // todo modal //

  .create-todo-main-modal {
    .todo-task {
      .todo-main-content {
        .drop-picker {
          margin: 15px 15px 0 15px;
        }
      }
    }
  }


  .todo-main-modal {
    .todo-task {
      .todo-main-content {
        .drop-picker {
          margin: 15px 15px 0 15px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .chatzy-main {
    .message-input {
      button {
        svg {
          height: 16px;
          width: 16px;
          stroke: white;
        }
      }
    }
  }

  .add-popup {
    .close {
      top: 15px;
    }
  }
}

@media (max-width: 480px) {
  .emojis-main {
    .contact-poll {
      .contact-poll-content {
        left: 0;
      }
    }
  }

  .chatzy-container {
    .chatzy-left-sidebar {
      .contact-list-tab {
        padding: 20px 20px 40px;
      }
    }
  }

  .chatzy-main {
    .messages {
      .contact-details {
        padding: 18px 15px !important;
      }
    }
  }


  .profile.online:before {
    right: 0px;
    bottom: 0px;
  }

  .contact-chat {
    >ul {
      >li {
        &.sent {
          .contact-name {
            .msg-box {
              .msg-setting-main {
                .badge.badge-img {
                  bottom: -16px;
                }
              }
            }
          }
        }
      }
    }
  }

  .search-form {
    .form-group {
      .icon-close {
        top: 0 !important;
        right: 10px;
        padding: 12px;
      }

      input {
        height: 46px;
        border-radius: 4px;
      }
    }

    &:before {
      top: 14px;
    }
  }

  .chatzy-main {
    .messages {
      .contact-details {
        .search-form.open {
          top: 18px;
        }
      }
    }
  }

  .chatzy-container {
    .chatzy-main {
      .message-input {
        margin: 0;
        bottom: 0;
        border-radius: 2px;
        width: calc(100% - 0px) !important;
      }
    }
  }

  .chatzy-container {
    .chatzy-left-sidebar {
      .recent-default {
        .chat {
          >.template-tab {
            padding: 10px 20px 20px;
          }
        }
      }
    }
  }

  .chatzy-main {
    .messages {
      .contact-details {
        ul {
          li {
            padding: 0 4px;

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  .dot-btn.grow:after {
    top: -6px;
    right: -4px;
  }

  .template-tab.tab-icon .nav-tabs .nav-item .nav-link {
    padding: 8px 15px;
    font-size: 14px;

    svg {
      height: 18px;
    }
  }

  .dot-btn:before {
    top: 0px;
  }

  .chatzy-container .main-nav.on~.chatzy-left-sidebar .dynemic-sidebar:not(.recent-default).active {
    min-width: 100%;
    width: 100%;
    margin-left: 0;
    z-index: 9999;
    transition: all 0.3s ease;
  }

  .recent-default {
    .search-form:before {
      top: 20px;
    }
  }

  .groupuser {
    .gr-chat-friend-toggle {
      .gr-chat-frind-content {
        width: 295px;

        &::before {
          right: 125px;
        }
      }
    }
  }

  .chatzy-main {
    .messages {
      .contact-details {
        ul {
          li {
            &.chat-friend-toggle {
              .icon-btn {
                width: 32px;
              }
            }
          }
        }
      }
    }
  }

  .groupuser {
    .gr-chat-friend-toggle {
      .gr-chat-frind-content {
        margin-right: -100px;
      }
    }
  }

  .chatzy-container {
    .chatzy-left-sidebar {
      .recent-default {
        .chat .search-form {
          height: 50px;
          top: 10px;
        }
      }
    }
  }

  .emojis-main .contact-poll .contact-poll-content {
    padding: 20px;

    ul {
      li {
        margin-bottom: 8px;
        font-size: 14px;
        padding-left: 0px;

        a {
          svg {
            width: 16px;
          }
        }
      }
    }
  }

  .contact-chat>ul>li.replies .msg-box li:first-child h5 {
    border-radius: 30px 25px 0 30px;
  }

  .chatzy-container .chatzy-left-sidebar .recent-default .chat>.template-tab .nav-tabs .nav-item .nav-link {
    min-width: 50px;
  }

  .chat-landing-page {
    .landing_page_slider {
      min-height: 380px;
    }
  }

  .template-tab>.nav-tabs>.nav-item .nav-link {
    padding: 7px 40px;
  }

  .chat-main {
    li {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .msg-setting-main {
    .msg-dropdown-main {
      .msg-dropdown {
        right: 0;
        left: unset;
      }
    }
  }

  .replies {
    .msg-setting-main {
      .msg-dropdown-main {
        .msg-dropdown {
          left: 0;
          right: unset;
        }
      }
    }
  }

  .contact-chat {
    margin-top: 55px;

    .profile {
      height: 40px;
      width: 40px;
      border-radius: 15px;
    }

    >ul>li {

      &+li {
        margin-top: 20px;
      }

      &.replies {
        .d-flex .profile {
          margin-left: 0.7rem !important;
        }
      }
    }
  }

  .chatzy-main .messages .contact-details {
    padding: 10px;
    top: 20px;
  }

  .contact-chat {
    .sent {
      .profile {
        &.mr-4 {
          margin-right: 0.7rem !important;
        }
      }
    }
  }

  .chat-tabs {
    ul {
      li {
        &.nav-item {
          width: 100%;
          margin: 0 7px;
        }
      }
    }
  }

  .chatzy-container {
    .chatzy-main {
      .messages {
        .contact-details {
          width: calc(100vw - 0px);
          top: 0px;
        }
      }
    }

    .chatzy-left-sidebar {
      .recent-default {
        .recent {
          padding-top: 0px;

          &~.chat {
            margin-top: 249px;
            height: calc(100vh - 170px);
            min-height: calc(100vh - 170px);
          }

          .recent-slider {
            padding-top: 0;
          }

          .mobile-back {
            background-color: transparent !important;
            width: unset;
            font-size: 13px;

            i {
              font-size: 14px;
            }
          }
        }

        .chat {
          >.template-tab.chat-tabs>ul>li {
            >a {
              padding: 2px 10px;
            }
          }
        }
      }
    }
  }

  .contact-chat .profile {
    height: 40px;
    width: 40px;
  }

  .chatzy-container {
    .chatzy-left-sidebar {
      .recent-default {
        .chat {
          .template-title {
            padding: 10px 20px 0 20px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .app-sidebar {
    .app-list {
      z-index: 999;
      position: relative;
    }

    &.active {
      .apps-ul {
        >li {
          &.active {
            right: 0;
            z-index: 9999;
          }
        }
      }
    }
  }

  .chatzy-main {
    .message-input {
      input {
        width: calc(100% - 75px);
        font-size: 16px;
      }
    }
  }

  .sidebar-toggle {
    .main-nav:not(.on) {
      ~.chatzy-main {
        &.small-sidebar {
          width: calc(100% - 00px);

          .messages {
            .contact-details {
              width: calc(100vw - 52px);
            }
          }

          .message-input {
            width: calc(100% - 00px);
          }
        }
      }
    }
  }

  .sidebar-active {
    .chatzy-container {
      .chatzy-main {
        &.small-sidebar {
          width: calc(100% - 1px);
        }
      }
    }

    .sidebar-toggle {
      .main-nav {
        &.on {
          ~.chatzy-main {
            &.small-sidebar {
              .contact-details {
                width: 89.3vw !important;
              }

              .message-input {
                width: calc(100% - 5px);
              }
            }
          }
        }

        ~.chatzy-main {
          .messages {
            .contact-details {
              width: 68vw;
            }
          }
        }
      }
    }
  }

  .sidebar-toggle {
    .main-nav {
      &.on {
        ~.chatzy-main {
          .contact-details {
            width: 65.5vw !important;

            ul {
              li {
                padding-left: 18px;
              }
            }
          }
        }
      }
    }
  }

  footer {
    .section-py-space {
      .footer-title {
        .according-menu {
          &:before {
            right: 10px;
          }
        }
      }
    }
  }

  header {
    .landing-header {
      &.fixed {
        right: unset;
      }
    }
  }

  .error-main {
    .error-contain {
      .animated-bg {
        i {
          height: 70px;
          width: 70px;
        }
      }
    }
  }

  // element page
  .img-10 {
    width: 4px !important;
  }

  .img-20 {
    width: 8px !important;
  }

  .img-30 {
    width: 10px !important;
  }

  .img-40 {
    width: 13px !important;
  }

  .img-50 {
    width: 20px !important;
  }

  .img-60 {
    width: 30px !important;
  }

  .img-70 {
    width: 40px !important;
  }

  .img-80 {
    width: 50px !important;
  }

  .img-90 {
    width: 60px !important;
  }

  .img-100 {
    width: 70px !important;
  }


  // to do modal //

  .create-todo-main-modal {
    .modal-body {
      .default-form {
        .dropdown.currency {
          width: 100%;
        }
      }
    }
  }

}

@media (max-width: 420px) {
  .chatzy-main {
    .messages {
      .contact-details {
        ul {
          li {
            padding: 0px 2px;

            &.volume-icons {
              display: none;
            }
          }
        }
      }
    }
  }

  // header
  header {
    .landing-header {
      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            margin-left: 20px;
          }
        }

        ul {
          li {
            a {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

}

@media (max-width: 360px) {

  // element page
  .img-10 {
    width: 2px !important;
  }

  .img-20 {
    width: 4px !important;
  }

  .img-30 {
    width: 5px !important;
  }

  .img-40 {
    width: 7px !important;
  }

  .img-50 {
    width: 9px !important;
  }

  .img-60 {
    width: 15px !important;
  }

  .img-70 {
    width: 25px !important;
  }

  .img-80 {
    width: 35px !important;
  }

  .img-90 {
    width: 45px !important;
  }

  .img-100 {
    width: 55px !important;
  }

  .nav-tabs {
    .nav-item {
      margin-right: 5px;
    }
  }

  .template-tab {
    .nav-tabs {
      .nav-item {
        .nav-link {
          min-width: 100px;
        }
      }
    }
  }
}
