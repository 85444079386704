/**=====================
     Reset css start
==========================**/

/*====== Padding css starts ======*/
$i: 0;

@while $i<=50 {
  .p-#{$i} {
    padding:#{$i}px;
  }

  $i: $i+5;
}

/*====== Padding css ends ======*/

/*====== Padding-left css starts ======*/
$i: 0;

@while $i<=50 {
  .p-l-#{$i} {
    padding-left:#{$i}px;
  }

  $i: $i+5;

}

/*====== Padding-left css ends ======*/

/*====== Padding-top css starts ======*/
$i: 0;

@while $i<=50 {
  .p-t-#{$i} {
    padding-top:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Padding-top css ends ======*/


/*====== Padding-bottom css starts ======*/
$i: 0;

@while $i<=50 {
  .p-b-#{$i} {
    padding-bottom:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Padding-bottom css ends ======*/

/*====== Padding-right css starts ======*/
$i: 0;

@while $i<=50 {
  .p-r-#{$i} {
    padding-right:#{$i}px;
  }

  $i: $i+5;
}

/*====== Padding-right css ends ======*/


/*====== Margin css starts ======*/
$i: 0;

@while $i<=50 {
  .m-#{$i} {
    margin:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin css ends ======*/

/*====== Margin-top css starts ======*/
$i: 0;

@while $i<=50 {
  .m-t-#{$i} {
    margin-top:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin-top css ends ======*/

/*====== Margin-Bottom css starts ======*/
$i: 0;

@while $i<=50 {
  .m-b-#{$i} {
    margin-bottom:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin-Bottom css ends ======*/

/*====== Margin-left css starts ======*/
$i: 0;

@while $i<=50 {
  .m-l-#{$i} {
    margin-left:#{$i}px !important;
  }

  $i: $i+5;
}

/*====== Margin-left css ends ======*/

/*====== Margin-right css starts ======*/
$i: 0;

@while $i<=50 {
  .m-r-#{$i} {
    margin-right:#{$i}px;
  }

  $i: $i+5;
}

/*====== Margin-right css ends ======*/

/*====== Image-sizes css starts ======*/
$i: 10;

@while $i<=100 {
  .img-#{$i} {
    width:#{$i}px !important;
  }

  $i: $i+10;
}

/*====== Image-sizes css ends ======*/

.rounded {
  border-radius: $radious5 !important;
}

.rouded15 {
  border-radius: $radious15 !important;
}

/*======= Text css starts ===========*/
$text-name: primary,
  success,
  danger,
  light,
  warning;
$text-color : $primary-color,
$success-color,
$danger-color,
$light-color,
$warning-color;

@each $var in $text-name {
  $i: index($text-name, $var);

  .font-#{$var} {
    color: nth($text-color, $i) !important;
  }
}

/*======= Text css ends ===========*/

/*======== stroke color start ===========*/
$stroke-name: primary,
  success,
  danger,
  light,
  warning,
  dark,
  muted;
$stroke-color : $primary-color,
$success-color,
$danger-color,
$light-color,
$warning-color,
$dark-color,
$muted-font;

@each $var in $stroke-name {
  $i: index($stroke-name, $var);

  .stroke-#{$var} {
    stroke: nth($stroke-color, $i) !important;
  }
}

/*======== stroke color end ===========*/

/*======== fill color start ===========*/
$fill-name: primary,
  success,
  danger,
  light,
  warning,
  dark,
  muted;
$fill-color : $primary-color,
$success-color,
$danger-color,
$light-color,
$warning-color,
$dark-color,
$muted-font;

@each $var in $fill-name {
  $i: index($fill-name, $var);

  .fill-#{$var} {
    fill: nth($fill-color, $i) !important;
  }
}

/*======== fill color end ===========*/

@each $bg-light-name, $bg-light-color in (primary, $primary-color),
(success, $success-color),
(danger, $danger-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {

  .bg-light-#{$bg-light-name} {
    background-color: rgba($bg-light-color, 0.15) !important;
    color: $bg-light-color;
    transition: all .4s ease-in-out;

    &:hover {
      background-color: rgba($bg-light-color, 1) !important;
      color: $white;
    }
  }
}

.nav-link {
  &:focus-visible {
    box-shadow: 0 0 0 0.25rem rgba($primary-color, .25);
  }
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba($primary-color, .25);
}

.app-sidebar {
  .todo-main {
    .todo-task {
      .drop-picker {
        input {
          min-width: 105px;
        }
      }
    }
  }
}

.chatzy-main {
  .messages {
    .contact-details {
      .left.d-flex {
        .media-right {
          display: none;
        }
      }
    }
  }
}

// - end music //
.right-chat-content {
  h5 {
    padding: 20px 0 20px 30px;
    color: $dark-color;
    font-weight: 500;
    border-top: 1px solid rgba($muted-font , 0.1);
  }
}

$background-name: primary,
  success,
  danger,
  light,
  pinked,
  info,
  warning;
$background-color: $primary-color,
  $success-color,
  $danger-color,
  $light-color,
  $pinked-color,
  $info-color,
  $warning-color;

@each $var in $background-name {
  $i: index($background-name, $var);

  .bg-#{$var} {
    background-color: nth($background-color, $i) !important;
  }
}

.bg-transparent {
  background-color: transparent !important;
}

.template-according {
  .card {
    .collapse {
      .input-group {
        flex-wrap: unset !important;

        .input-group-prepend {
          .input-group-text {
            border-radius: 0px;
          }
        }
      }
    }
  }
}

.app-sidebar {
  &.active {
    .apps-ul {
      .template-title {
        .d-flex {
          .flex-grow-1 {
            text-align: right;
          }
        }
      }
    }
  }
}

button:focus:not(:focus-visible) {
  box-shadow: none;
}

.navbar-light .navbar-brand {
  margin-left: 15px;
}

header .landing-header .navbar-collapse .navbar-nav .nav-item.active .nav-link {
  color: $primary-color;
}

.navbar-expand-sm .navbar-nav {
  margin-left: auto;
}

.form-select:focus {
  box-shadow: 0 0 0 0.25rem rgba($primary-color, .25);
}


.integratin {
  li {
    .d-flex {
      .media-right {
        .profile {
          margin-right: 0px;
        }
      }
    }
  }
}

.template-sosial {
  li {
    a {
      i {
        color: $primary-color;
      }
    }
  }
}

@media (max-width: 576px) and (min-width: 400px) {
  .col-xsm-6 {
    width: 50%;
  }
}

.form-check-input:checked {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}

.form-check-input:focus {
  border-color: $primary-color !important;
  box-shadow: 0 0 0 4px rgba($primary-color, 0.1) !important;
}

.form-switch {
  .form-check-input {
    height: 20px;
    width: 36px;
  }
}
