.chat-main{
  margin-bottom: 10px;
  li{
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
//    transition: all 0.3s ease;
    border-bottom: 1px solid rgba($muted-font , 0.15);
    &:last-child{
      border-bottom: none;
    }
    .chat-box {
      .icon-btn.btn-outline-light{
        position: absolute;
        right: 0;
        i{
          font-size: 14px !important;
        }
        &:last-child{
          right: 45px;
        }
      }
      .favourite{
        
        svg{
          stroke: none;
          fill: $primary-color;
        }
        &.btn-primary{
          svg{
            fill: $white;
          }
        }
      }
    }
    .details{
      h5{
        img{
          height: 20px;
        }
      }
    }
    &.active{
      background-color: rgba($primary-color , 0.1);
      //transition: all 0.3s ease;
      .details{
        h5{
          font-weight: 600;
          color: $primary-color;
          letter-spacing: 1px;

        }
      }
    }
  }
  li{
    &.pined{
      .chat-box{
        .date-status{
          .ti-pin2{
            opacity: 1;
            //transition: all 0.3s ease;
            color: $primary-color;
          }
        }
      }
    }
    .chat-box{
      position: relative;
      .profile{
        position: absolute;
        left: 0;
      }
      .details{
        padding: 4px 50px 8px 70px;

        h5{
          padding-top: 8px;
          max-width: 200px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          svg{
            height: 20px;
            width: 20px;
            vertical-align: middle;
            margin-right: 4px;
          }
        }
      }
      .date-status{
        position: absolute;
        right: 0;
        top: 0px;
        text-align: right;
        .ti-pin2{
          font-size: 16px;
          opacity: 0;
          //transition: all 0.3s ease;
          rotate: 45deg;
          display: inline-block;
        }
        .status{
          padding-top: 8px;
          font-weight: 600;
          letter-spacing: 0.5px;
        }

        svg {
          height: 20px;
          width: 20px;
        }

        .sm{
          font-weight: 600;
          border-radius: 100%;
          height: 20px;
          width: 20px;
          padding: 0px 6px;
          font-size: 12px;
          margin-top: 6px;
        }
      }
      &:hover{
        .date-status{
          .ti-pin2{
            opacity: 1;
            //transition: all 0.3s ease;
          }
        }
      }
    }
  }
}


// group element css //
ul.grop-icon {
  line-height: 1 ;
  display: flex;
  align-items:center ;
  li {
    margin-left: 0;
    margin-right: 0;
    padding-left:0;
    padding-right:0;
    padding-top: 0 ;
    padding-bottom:0;
    border: 2px solid $white ;
    border-radius: 50% ;
    transition: z-index 0.3s ease;
    &:hover {
      z-index: 9 ;
      transition: z-index 0.3s ease;
    }
    &:nth-child(2),&:nth-child(3) {
      margin-left: -10px;
    }
    &:last-child{
      font-size: 12px;
      color: $dark-color;
      font-weight: 700;
      border: none ;
      border-radius: 0 ;
      margin-left: 5px;
    }
    img {
      width: 15px;
      height: 15px;
      border-radius: 50% ;
    }
  }
}



// group box //
.group-main{
  margin-bottom: 15px;
  li{
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    &.active{
      background-color: rgba($primary-color , 0.1);
      .chat-box {
        .details{
          h5{
            font-weight: 600;
            color: $primary-color;
          }
        }
      }
    }
  }
  .tab-content {
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 40px;
    padding-right: 40px;
    border-top: 1px solid $border-color;
  }
  .group-box{
    position: relative;
    .profile{
      position: absolute;
      left: 0;
      span{
        font-size: 26px;
        font-weight: 700;
      }
    }
    .details{
      padding-left: 75px;
      padding-top: 4px;
      padding-bottom: 13px;
      h6{
        padding-top: 5px;
        line-height: 2 ;
      }
    }
    .date-status{
      position: absolute;
      right: 0;
      top: 8px;
      text-align: right;
      .status{
        padding-top: 8px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
      .sm{
        font-weight: 600;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        padding: 0px 6px;
        font-size: 12px;
        margin-top: 6px;
      }
    }
  }
}

