
//colors
$black: #000000;
$white: #ffffff;
$primary-color: #A83A3D;
$primary-light: #FFFEF5;
$success-color: #A83A3D;
$danger-color: #FF5151;
$warning-color: #ffc514;
$light-color: #F3F2E9;
$dark-color: #2C2C2C;
$dark-icon: #010D21;
$white-color: #ffffff;
$gray:  #828282;
$gray1: #e7f5f2;
$gray2: #f4f5f5;
$pinked-color: #F98BAE;
$info-color : #72CCCF;
$light-primary: #D9D9D9;


$facebook: 	#3b5998;
$instagram: #e4405f;
$twiter: #55acee;
$google: #dc4e41;
$linkedin: #0e76a8;

$slack: #3aaf85;
$dark : #020D0B;
$light : #043429;
$light-dark: #041815;
$dark-border : #414248;
$border-color2: #ddd;
$cross-color: rgba(#01AA85 , 0.1);


// Font family
$font-1: 'Manrope', sans-serif;
$font-inter:  "Inter", sans-serif;
$landing-cursive: 'Caveat', cursive;
$icon-stroke-width : 3px;
$border-color: rgba(127, 131, 132, 0.15);
$border-color1: #e5e8e9;
$muted-font: #7F8384;
$radious100 : 100%;
$btn-radious: 10px;
$radious25 : 25px;
$radious5 : 5px;
$radious15 : 15px;
$radious20 : 20px;
$radious50 : 50%;

//Grid setting 
$main-nav-width: 100px;
$left-sidebar-width: 420px;
$right-sidebar-width: 429px;

// sibar seting
$sidebar-spacing: 25px;
$contant-spacing: 45px;
$left-sidebar-spacing: 30px;


// landing page
$header-back: #d5eee9;
$red: #ff3030 ;
$light-blue:#1c77ed ;
$light-blue1:#1da8fd;
$black1:#595959;
$test-back:#e9f5ff ;
$transitionDefault: 0.25s ease-out 0.10s;
$value : 5px;
