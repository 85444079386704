@import "utils/variables.scss";
@import "style";
html {
  &.style1 {
    $primary-color: #FFC299;
    $primary-light: #FAEECD;
    $gray1: #e4e7ef;
    $dark: #111726;
    $light: #242f4a;
    $light-dark: #192136;
    @import "style";
  }
  &.style2 {
    $primary-color: #FF7B95;
    $primary-light: #FFB4B4;
    $gray1: #e1f1ee;
    $dark: #031a28;
    $light: #102a44;
    $light-dark: #04273c;
    @import "style";
  }
  &.style3 {
    $primary-color: #AEAAF2;
    $primary-light: #E9D3ED;
    $gray1: #f4effa;
    $dark: #021617;
    $light: #053235;
    $light-dark: #042022;
    @import "style";
  }
  &.style4 {
    $primary-color: #A1B5FD;
    $primary-light: #C2E9FB;
    $gray1: #daf6f5;
    $dark: #12080d;
    $light: #2e1522;
    $light-dark: #22111a;
    @import "style";
  }
  &.style5 {
    $primary-color: #C4F4A9;
    $primary-light: #A9F1DF;
    $gray1: #faebf0;
    $dark: #0d0914;
    $light: #271c39;
    $light-dark: #130d1d;
    @import "style";
  }
  &.style6 {
    $primary-color: #7a3e65;
    $primary-light: #f3eaf0;
    $gray1: #faeae0;
    $dark: #1d0f18;
    $light: #361c2d;
    $light-dark: #261420;
    @import "style";
  }
}
