.custom-dropdown{
  height: 0;
  width: 0;
  position: absolute;
  right: 0;
  background-color: $white;
  z-index: 99;
  opacity: 0;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid $border-color;
  border-radius: 15px;
  top: 85px;
  transition: all 0.3s ease;
  &.open{
    opacity: 1;
    transition: all 0.3s ease;
    height: 200px;
    width: 300px;
  }
}
.onclick-dropdown{
  cursor: pointer;
}
// emojis start //
.emojis-main {
  .emojis-contain {
    background-color: $white;
    .emojis-sub-contain {
      &:before {
        border-top: 12px solid $white;
      }
    }
  }
  .contact-poll {
    .contact-poll-content {
      background-color: $white;
      box-shadow: 0px 2px 15px 0px rgba($muted-font, 0.08);
      ul {
        li {
          svg{
            fill: $white;
          }
          div{
            border-radius: $radious50;
          }
          &:hover {
            a {
              color: $primary-color;
            }
          }
        }
      }
      &:before {
        border-top: 12px solid $white;
      }
    }
  }
}

.main-nav{
  .status{
    > div{
      &:before{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: $primary-color;
        left: 0;
        z-index: -1;
        top: 0;
        border-radius: 100%;
      }
    }
  }
  .user-popup{
    height: 43px;
    width: 43px;
    padding: 3px;
    border-radius: 100%;
    margin: 0 auto;
    position: relative;
    &.two{
      > div{
        &:after{
          content: "";
          position: absolute;
          height: 3px;
          width: 100%;
          background-color: $white;
          left: 0;
          z-index: -1;
          top: 22px;
        }
      }
    }
    &.three{
      > div{
        &:after{
          content: "";
          position: absolute;
          left: -6px;
          z-index: -1;
          top: -5px;
          width: 0;
          height: 0;
          border-left: 33px solid transparent;
          border-right: 28px solid transparent;
          border-bottom: 43px solid $white;
        }
      }
    }
    > div{
      height: 37px;
      width: 37px;
      border-radius: $radious100;
      margin: 0 auto;
    }
  }
}

// chat frind popup //
.chat-friend-toggle {
  position: relative;
  .chat-frind-content {
    position: absolute;
    top: 54px;
    right:0;
    padding: 20px;
    background-color: $white;
    text-align: left;
    display: none;
    text-transform: capitalize;
    border-radius: 8px;
    ul {
      li {
        padding-left: 34px !important;
        position: relative;
        display: inline-flex !important;
        align-items: center;
        height: 30px;
        width: 155px;
        &:first-child{
          margin-bottom: 15px;
        }
        a {
          position: absolute;
          left: 0;
          svg {
            height: 20px;
            width: 20px;
          }

        }
        h5{
          color: $dark-color;
          font-weight: 500;
        }
        &:before {
          border-left:5px solid $primary-color;
          content: "";
          position: absolute;
          top: 0;
          left: 15px;
        }
      }
    }
  }
}
.user-status{
  position: relative;
  padding: 2px;
  z-index: 0;
  &:before{
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $primary-color;
    left: 0;
    z-index: -1;
    top: 0;
    border-radius: 100%;
  }
}