.login-page{
  overflow-x: hidden;

  .login-bg-img{
    padding: 50px;
    background-color: $primary-light;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: fixed;
    width: 50%;
    @media (max-width: 991px) {
      padding: 0;
      display: none;
    }
  }
  .left-page{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: 100%;
    min-height: 100vh;
    
    @media (max-width: 991px) {
      background-color: $primary-light;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      height: 100vh;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($white , 0.4);
        z-index: -1;
      }
    }
    @media (max-width: 991px) {
      height: 100%;
    }
    .login-content{
      width: fit-content;
      border: 1px solid rgba($muted-font , 0.15);
      padding: 40px 50px;
      border-radius: 8px;
      background-color: $white;
      box-shadow: 0px 2px 15px 0px rgba($primary-color, 0.08);
      .login-content-header {
        img {
          height: 50px;
        }
      }
    }
    h3{
      font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
      font-family: $font-1;
      font-weight: 600;
      padding-bottom : 5px;
      span {
        font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
      }
    }
    .form1{
      label{
        font-weight: 500;
        font-size: 16px;
        color: $dark-color;
      }
      .form-control ,
      .form-select{
        padding: 7px 15px;
        background-color: rgba($muted-font , 0.05);
        border: 1px solid rgba($muted-font , 0.15);
      }
      >.form-group{
        margin-bottom: 15px;
      }
    }
    .termscondition{
      border-top: 1px dashed rgba($muted-font , 0.15);
    }
  }
}
.left-page{
  @media (max-width: 991px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%  , -50%);
    padding: 50px 100px;
    background: rgba($white , 0.5);
    width: 100%;
    z-index: 1;
  }
}

.index-main {
  .for-light{
    display: none;
    margin: 0;
  }
  .for-dark{
    display: block;
    margin: 0;
  }
}

.for-light{
  display: block;
  margin: 0;
}
.for-dark{
  display: none                                           ;
  margin: 0;
}

.form-group{
  .buttons {
    .btn{
      padding: 10px 40px;
      margin-bottom: 10px;
    }
  }
}
.medialogo {
  margin-bottom : 30px;
  ul{
    display: flex;
    justify-content: center;
  }
  .btn-facebook{
    background-color:#2c67ce;
    color:$white;
  }
}
.login-page{
  @media (max-width: 600px) {
    .left-page {
      padding: 50px 8px;
    }
  }
  @media (max-width: 400px) {
    .left-page {
      .login-content{
        padding: 40px 20px;
      }
    }
  }
}
