/**=====================
    Typography css start
==========================**/
body {
    background-color: $white;
    font-size: calc(12px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
    overflow-x: hidden;
    font-family: $font-1, $font-1;
    color: $dark-color;

    &.main-page {
        overflow: hidden;
    }
}

.main {
    overflow: hidden;
}

ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
    padding-right: 0;
}

::-moz-selection {
    color: $white;
    background: $primary-color;
}

::selection {
    color: $white;
    background: $primary-color;
}

* a {
    color: $primary-color;
    text-decoration: none !important;
}

*.btn:focus {
    box-shadow: rgba($primary-color, 0.15) !important;
}

a {
    cursor: pointer;
    text-decoration: none !important;
}

:focus {
    outline-color: rgba($primary-color, 0.15);
    text-decoration: none !important;
}

select {
    &:focus {
        outline: none !important;
    }
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

svg {
    vertical-align: baseline;
}

input {
    &:focus {
        outline-color: rgba($primary-color, 0.15);
    }
}

//Custom css End

//Typography css start
h1 {
    font-family: $font-1;
    font-size: calc(20px + (34 - 20) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 800;
    color: $dark-color;
    margin: 0;
}

h2 {
    font-family: $font-1;
    font-size: calc(20px + (22 - 20) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    color: $dark-color;
    margin: 0;
}

h3 {
    font-family: $font-1;
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    color: $dark-color;
    margin: 0;
}

h4 {
    font-family: $font-1;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    color: $dark-color;
    margin: 0;
}

h5 {
    font-family: $font-1;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    color: $muted-font;
    margin: 0;
}

h6 {
    font-family: $font-1;
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    color: $muted-font;
    margin: 0;
}

span {
    font-family: $font-1;
    font-size: 14px;
    font-weight: 600;
    color: $dark-color;
}

.title2 {
    font-family: $font-1;
    font-size: calc(22px + (36 - 22) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    color: $white;
    margin: 0;
}

p {
    font-family: $font-1;
    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    color: $muted-font;
}

a {
    &:hover {
        text-decoration: none;
        color: $primary-color;
    }
}

.template-title {
    position: relative;

    h4 {
        font-family: $font-1;
        padding-top: 5px;
    }
}

/**=====================
    Typography css ends
==========================**/