/*=====================
ganral  css start
==========================*/
.inner-card {
  background-color: $white;
  padding: 15px;
}

.header-inner {
  padding-bottom: 55px;
}

.navbar-light .navbar-nav .nav-link {
  margin-top: -3px;
}

.links_horizontal {
  p {
    text-align: end;
  }
}

.landing-header {
  &.fixed {
    .navbar-collapse {
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: rgba($white, 1);
          }
        }
      }
    }
  }
}

.inner-page {
  background-color: rgba($primary-color, 0.025);

  header {
    position: relative;
    padding-bottom: 30px;

    .landing-header .landing-header-left {
      width: 100%;
      justify-content: space-between;

      .main-menu ul {
        li {
          a {
            font-weight: 500;
            color: $dark-color;
          }
        }
      }
    }
  }

  section {
    background-image: linear-gradient(145deg, $light-primary 0%, $primary-color 100%);
  }
}

.footer-main {
  .footer-contant {
    .logo {
      a {
        img {
          margin: unset !important;
        }
      }
    }
  }
}

/*=====================
  faq css start
  ==========================*/
.faq-block {
  .faq-title {
    text-align: center;
    margin-bottom: 44px;
  }
}

/*=====================
 404 css start
 ==========================*/
.error-images {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 745px;
  }
}

// counter

.popup-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.inner-page {
  &~div .testimonial-back-content {
    display: none;
  }
}